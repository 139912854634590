<template>
    <div class="currency" @click="toggler()">
        <BaseIconTitle
            :title="'Dashboard currency'"
            :material-icon="'price_check'"
        />

        <BaseSelectModalBox
            :position="selectPosition"
            :placeholder="activeCurrency"
            :options="Object.entries(currencies).map(([key, value]) => ({
                    key: value,
                    title:value ? value : 'USD',
                }))
            "
            :state="state"
            v-model="currency"
            @update="changeCurrency"
        >
            <template #option="{option}">
                <img :src="staticBase+`currencies/${option.key ? option.key : 'USD'}.png`" alt="">
                <div class="name">{{ option.title }}</div>
            </template>
        </BaseSelectModalBox>
    </div>
</template>

<script>
import BaseIconTitle from "@/new-design/shared/ui/titles/BaseIconTitle.vue"
import BaseSelectModalBox from "@/new-design/shared/ui/Inputs/BaseSelectModalBox.vue"
import {ACTIVE_CURRENCY} from "@/store/mutations.type"
import {mapGetters} from "vuex"
import {STATIC_BASE} from "@/common/config"
import toggler from "@/new-design/shared/mixins/toggler"

export default {
    name: "DashboardCurrency",
    components: {BaseSelectModalBox, BaseIconTitle},
    mixins: [toggler],
    props: {
        selectPosition: {
            type: String,
        }
    },
    data() {
        return {
            staticBase: STATIC_BASE
        }
    },
    computed: {
        currency: {
            get() {
                return this.activeCurrency
            },
            set(value) {
                this.$store.commit('SET_LANG_ACTIVE', value)
            }
        },
        ...mapGetters(['currencies', 'activeCurrency'])
    },
    methods: {
        changeCurrency(currency) {
            localStorage.activeCurrency = currency
            this.$store.commit(ACTIVE_CURRENCY, currency)
        }
    }
}
</script>

<style scoped>
.currency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
</style>
