<template>
    <div class="dark-mode">
        <BaseIconTitle
            :title="'Dark mode'"
            :material-icon="'dark_mode'"
        />
        <BaseSwitch
            v-model="mode"
            @click.native="changeTheme()"
        />
    </div>
</template>

<script>
import BaseIconTitle from "@/new-design/shared/ui/titles/BaseIconTitle.vue"
import BaseSwitch from "@/new-design/shared/ui/Inputs/BaseSwitch.vue"
import {TRADINGVIEW_BG, TRADINGVIEW_LINE, TRADINGVIEW_THEME} from "@/common/config";
import {SET_LIGHT_THEME, SET_THEME_COLORS} from "@/store/mutations.type"
import {mapGetters} from "vuex"

export default {
    name: "DarkMode",
    components: {BaseSwitch, BaseIconTitle},
    data() {
        return {
            mode: this.lightTheme
        }
    },
    computed: {
        ...mapGetters(['lightTheme','themeColors'])
    },
    methods: {
        changeTheme() {
            this.$store.commit(SET_LIGHT_THEME, !this.lightTheme)
            localStorage.setItem('theme',this.lightTheme ? 'white' : 'black')
            localStorage.setItem('tradingview.current_theme.name',this.lightTheme ? 'Light' : TRADINGVIEW_THEME)

            let theme = this.lightTheme ?
                {
                    ...this.themeColors,
                    'background-dark': (JSON.parse(localStorage.getItem('new-design') || false) ? "#e8eefa" : "#fff"),
                    'line': '#fff',
                    'font': '#8c8f96'
                } :
                {
                    ...this.themeColors,
                    'background-dark': (JSON.parse(localStorage.getItem('new-design') || false) ? "#161b24" : TRADINGVIEW_BG),
                    'line': TRADINGVIEW_LINE,
                    'font': '#8c8f96'
                }

            this.$store.commit(SET_THEME_COLORS, theme)

            if (document.body.classList.contains('light-theme')) {
                document.body.classList.remove('light-theme')
            }

            if (this.lightTheme) {
                document.body.classList.add('light-theme')
            }
        }
    }
}
</script>

<style scoped>
.dark-mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
