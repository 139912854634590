<template>
    <div class="information">
        <div class="meter">
            <div class="types label__mediumprominent">
                <div>{{ tokens.assetInfoModal.sell }}</div>
                <div>{{ tokens.assetInfoModal.buy }}</div>
            </div>
            <div class="line" :style="{ 'gridTemplateColumns': `auto ${trend}%` }">
                <div class="line-red"></div>
                <div class="line-green"></div>
            </div>
            <div class="precent label__mediumprominent">
                <div class="red">{{ (100 - trend).toFixed(1) + '%' }}</div>
                <div class="green">{{ trend.toFixed(1) + '%' }}</div>
            </div>
        </div>

        <div class="stat">
            <div class="stat-buttons">
                <div class="stat-button label__large"
                    :class="{ active: candle === '60 min' }"
                    @click="candleChange('60 min', 0)">
                        {{ getTimeStampData('60 min').buttonName }}
                </div>
                <div class="stat-button label__large"
                    :class="{ active: candle === '1 day' }"
                    @click="candleChange('1 day', 1)">
                        {{ getTimeStampData('1 day').buttonName }}
                </div>
                <div class="stat-button label__large"
                    :class="{ active: candle === '1 week' }"
                    @click="candleChange('1 week', 2)">
                        {{ getTimeStampData('1 week').buttonName }}
                </div>
                <div class="stat-button label__large"
                    :class="{ active: candle === '1 month' }"
                    @click="candleChange('1 month', 3)">
                        {{ getTimeStampData('1 month').buttonName }}
                </div>

                <div class="stat-button label__large"
                    :class="{ active: candle === '3 months' }"
                    @click="candleChange('3 months', 4)">
                        {{ getTimeStampData('3 months').buttonName }}
                </div>
                <div class="stat-button label__large"
                    :class="{ active: candle === '1 year' }"
                    @click="candleChange('1 year', 5)">
                        {{ getTimeStampData('1 year').buttonName }}
                </div>
            </div>

            <div class="stat-info">
                <div class="info-item">
                    <div>1 {{ getTimeStampData(this.candle).name }}</div>
                    <div class="candle title__medium"
                        :class="changePrice[getTimeStampData(this.candle).index] < 0 ?
                            'red' : (changePrice[getTimeStampData(this.candle).index] > 0 ? 'green' : '')">

                            {{ isFinite(changePrice[getTimeStampData(this.candle).index]) ?
                                changePrice[getTimeStampData(this.candle).index] + '%' : tokens.assetInfoModal.loading }}
                    </div>
                </div>
            </div>

            <div class="chart">
                <highcharts :options="chartOptions" />
            </div>

            <div class="bottom-info">
                <div class="info-title">
                    <div>{{ tokens.assetInfoModal.low }}</div>
                    <div>{{ tokens.assetInfoModal.high }}</div>
                </div>
                <div class="info-line">
                    <div class="round-wrapper" :style="`left: ${change[getTimeStampData(this.candle).index]}%`">
                        <div class="round"></div>
                    </div>
                </div>
                <div class="info-price">
                    <div>{{ newCandleData[getTimeStampData(this.candle).index].low }}</div>
                    <div>{{ newCandleData[getTimeStampData(this.candle).index].high }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import RestService from "@/common/rest.service";

export default {
    name: "Information",
    props: ['symbolActive'],
    mounted() {
        for (let i = 0; i < this.candleDataTime.length; i++) {
            (i => {
                RestService.get('/quotes/' + this.symbolActive + '/history', {
                    frame: this.candleDataTime[i] * 60,
                    count: this.candleDataCount[i],
                    offset: 0,
                    group: this.symbols[this.symbolActive].group
                })
                    .then(data => {
                        this.data = []

                        for (let j = 0; j < this.candleDataCount[i]; j++) {
                            if (data[j] === undefined) {
                                break
                            }

                            this.data[j] = data[j][3]
                        }
                        let min = Math.min.apply(null, this.data)
                        let max = Math.max.apply(null, this.data)
                        this.newCandleData[i].newData = this.data
                        this.newCandleData[i].low = min
                        this.newCandleData[i].high = max

                        this.chartOptions.series[0].data = this.newCandleData[0].newData
                        this.chartOptions.yAxis.min = this.newCandleData[0].low
                        this.chartOptions.yAxis.max = this.newCandleData[0].high
                    })
            })(i);
        }
    },
    data() {
        return {
            load: false,
            candle: '60 min',
            candleTime: 0,
            data: [],
            candleDataTime: [1, 30, 240, 1440, 60, 1440],
            candleDataCount: [59, 47, 41, 29, 89, 51],
            newCandleData: [{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            },{
                newData: [],
                low: 0,
                high:0
            }],
            chartOptions: {
                title: false,
                credits: false,
                chart: {
                    backgroundColor: 'transparent',
                    height: 100,
                    maxWidth: 500,
                    margin: 0,
                    type: 'line',
                },
                plotOptions: {
                    areaspline: {
                        fillOpacity: 0.5
                    },
                    series: {
                        lineWidth: 1,
                        fillColor: {
                            stops: [
                                [0, '#AFC6FF'],
                                [1, 'rgb(22, 27, 36)'],
                            ]
                        },

                    }
                },
                legend: false,
                xAxis: {
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    gridLineWidth: 0,
                    tickLength: 0,
                    min: 0,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            fontSize: '0px'
                        },
                    },
                    min: 0,
                    gridLineWidth: 0,
                    tickLength: 0,
                    display: true,
                },
                lineWidth: 1,
                threshold: null,
                innerSize: '60%',
                enableMouseTracking: true,
                tooltip: {
                  enabled: false
                },
                series: [{
                    name: '60 min',
                    data: [],
                    marker:{
                        enable: false,
                        fillColor: 'transparent',
                        states: {
                          hover: {
                            enabled: false
                          }
                        }
                    },
                }]
            },
            timeStampObj: {
                /* after transfering the text conent to langs.js need to corrected this object */
                '60 min': {
                    name: 'hour',
                    buttonName: '1H',
                    index: 0
                },
                '1 day': {
                    name: 'day',
                    buttonName: '1D',
                    index: 1
                },
                '1 week': {
                    name: 'week',
                    buttonName: '1W',
                    index: 2
                },
                '1 month': {
                    name: 'month',
                    buttonName: '1M',
                    index: 3
                },
                '3 months': {
                    name: 'months',
                    buttonName: '3M',
                    index: 4
                },
                '1 year': {
                    name: 'year',
                    buttonName: '1Y',
                    index: 5
                },
            }
        }
    },
    methods: {
        candleChange(candle, time) {
            this.candle = candle
            this.candleTime = time
        },
        getTimeStampData(timeStamp) {
            return this.timeStampObj[timeStamp] || this.timeStampObj['60 min']
        },

    },
    computed: {
        trend() {
            return (this.symbolActive) ? this.symbols[this.symbolActive].quote.trend : 50
        },
        angle() {
            return 90 / 50 * this.trend - 90
        },
        changePrice() {
            let changePrice = []
            for(let time = 0; time<6; time++) {
                let result = ((this.symbols[this.symbolActive].quote.bid - this.newCandleData[time].newData[1]) / this.newCandleData[time].newData[1]) * 100
                changePrice.push(result > 0 ? '+' + result.toFixed(2) : result.toFixed(2))
            }

            return changePrice
        },
        change() {
            let changeArr = []
            for(let time = 0; time<6; time++) {
                let change = ((this.symbols[this.symbolActive].quote.bid - this.newCandleData[time].low) / (this.newCandleData[time].high - this.newCandleData[time].low)) * 100
                if (change >= 100) {
                    changeArr.push(98)
                } else if (change <= 0) {
                    changeArr.push(2)
                } else {
                    changeArr.push(change || 2)
                }
            }
            return changeArr
        },
        ...mapGetters(['symbols', 'themeColors', 'modalAssetsInfo', 'tokens'])
    },
    watch: {
        themeColors(val) {
            this.chartOptions.series[0].color = val['brand']
        },
        candleTime(el) {
            this.chartOptions.series[0].name = this.candle
            this.chartOptions.series[0].data = this.newCandleData[el].newData
            this.chartOptions.yAxis.min = this.newCandleData[el].low
            this.chartOptions.yAxis.max = this.newCandleData[el].high
        },
    }
}
</script>

<style scoped>

.meter {
    padding: 16px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.types,
.precent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    width: 100%;
}

.line {
    width: 100%;
    display: grid;
    grid-gap: 5px;
    overflow: hidden;
    position: relative;
    height: 6px;
    transition: all 0.3s ease;
}

.line .line-red,
.line .line-green {
    border-radius: 10px;
    width: 100%;
}

.line .line-red {
    background-color: var(--Custom-RedFixed)
}

.line .line-green {
    background-color: var(--Custom-GreenFixed)
}

.red {
    color: var(--Custom-RedFixed)
}

.green {
    color: var(--Custom-GreenFixed)
}

.stat-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
}

.stat-button {
    padding: 4px 9px;
    border-radius: 4px;
    border: 1px solid var(--System-OutlineVariant);
    white-space: nowrap;
    cursor: pointer;
    color: var(--System-OnSurfaceVariant);
    transition: all .3s ease;
}

.stat-button.active,
.stat-button:hover {
    background: var(--System-Surface5);
    border-color: var(--System-Surface5);
    color: var(--System-Primary);
}

.stat-info {
    padding-inline: 16px;
}

.stat-info .info-item {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.bottom-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 16px;
}

.info-title {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.info-line {
    height: 4px;
    border-radius: 8px;
    background: var(--System-SecondaryContainer);
}

.info-line .round-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 10px;
    background: #161b24;
    transition: all .3s ease;
}

.round {
    width: 4px;
    height: 4px;
    background: var(--System-Secondary);
    border-radius: 50%;
}

.round:after,
.round:before {
    content: '';
    width: 6px;
    height: 4px;
    background: var(--System-SecondaryContainer);
    border-radius: 50%;
    position: absolute;
}

.round:after {
    left: -50%;
    transform: translateX(50%)
}

.round:before {
    right: -50%;
    transform: translateX(-50%)
}

.info-price {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    justify-content: space-between
}
</style>
