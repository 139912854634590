<template>
    <div :class="{hide: !modals.profitCalculator}" class="modal-wrapper" @mousedown.stop="hideModal">
        <div class="modal ElevationDark__5" @mousedown.stop>
            <div class="title-wrapper">
                <BaseModalTitle>
                    <template #left>
                        <i class="material-icons close arrow-icon" @click="hideModal">{{ 'arrow_back' }}</i>
                        <h4 class="title title__medium"> {{ 'Profit calculator' }}</h4>
                    </template>
                    <template #right>
                        <BaseToggleIconButton 
                            class="cross-icon" 
                            :button-style="'secondary'" 
                            @click.native="hideModal">
                                <template #icon>
                                    <i class="material-icons">close</i>
                                </template>
                        </BaseToggleIconButton>
                    </template>
                </BaseModalTitle>
            </div>
            <BaseModalTabs 
                :tabs="[tokens.profitCalculatorModal.buy , tokens.profitCalculatorModal.sell]"
                @updateActiveTab="activeTabHandler" />
            <div class="inner">
                <div class="inner-inputs">
                    <BaseInput 
                        v-model="volume" 
                        :placeholder="'0'" 
                        :title="'Volume'" 
                        :type="'number'" />
                    <BaseInput 
                        v-model="entryPrice" 
                        :placeholder="'0'" 
                        :title="'Price'" 
                        :type="'number'" />
                    <BaseInput 
                        v-model="stopLoss" 
                        :placeholder="'0'" 
                        :title="'Take Profit'" :type="'number'" />
                    <BaseInput 
                        v-model="takeProfit" 
                        :placeholder="'0'" 
                        :title="'Stop Loss'" 
                        :type="'number'" />
                </div>
                <div class="max-position body__small">
                    {{ tokens.profitCalculatorModal.maxPosition }}: {{ Math.abs(Number(maxPosition.toFixed(2))) }} {{
                    symbol.alias }}
                </div>

                <div class="actions">
                    <div class="currency body__large" v-tooltip="symbol.alias">
                        <div :class='`symbol symbol-${symbol.symbol}`'></div>
                        <span>{{ symbol.alias }}</span>
                    </div>
                    <BaseButton 
                        :button-style="isInputsEmpty ? 'filled-primary' : 'on-surface'" 
                        :button-text="tokens.profitCalculatorModal.calculate" 
                        :disabled="!isInputsEmpty" 
                        @click.native="calculate" />
                </div>

                <ul class="results">
                    <li v-show="showLeverage == 1" class="item">
                        <h3 class="item-name body__small">{{ tokens.profitCalculatorModal.leverage }}</h3>
                        <div class="item-value body__medium">1:{{ results.leverage }}</div>
                    </li>
                    <li class="item">
                        <h3 class="item-name body__small">{{ tokens.profitCalculatorModal.requiredMargin }}
                        </h3>
                        <div class="item-value body__medium">{{ results.margin }} <span>{{
                            activeAccount.currency
                        }}</span></div>
                    </li>
                    <li class="item">
                        <h3 class="item-name body__small">{{ tokens.profitCalculatorModal.profitFromTP }}
                        </h3>
                        <div class="item-value body__medium">{{ results.profit }} <span>{{
                            activeAccount.currency
                        }}</span></div>
                    </li>
                    <li class="item">
                        <h3 class="item-name body__small">{{ tokens.profitCalculatorModal.lossFromSL }}
                        </h3>
                        <div class="item-value body__medium">{{ Math.abs(results.loss) }} <span>{{
                            activeAccount.currency
                        }}</span></div>
                    </li>
                    <li class="item">
                        <h3 class="item-name body__small">{{ tokens.profitCalculatorModal.roe }}</h3>
                        <div class="item-value body__medium">{{ results.roe > 0 ? '+' : '' }}{{
                            results.roe.toFixed(2) }}
                            <span>%</span>
                        </div>
                    </li>
                    <li class="item">
                        <h3 class="item-name body__small">PIP</h3>
                        <div class="item-value body__medium">{{ results.pip }} <span>{{
                                activeAccount.currency }}</span></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL} from '@/store/mutations.type'
import {currencySign} from '@/common/helpers'
import {SHOW_LEVERAGE} from "@/common/config"
import BaseModalTitle from "@/new-design/shared/ui/titles/BaseModalTitle.vue";
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue";
import BaseModalTabs from "@/new-design/shared/ui/tabs/BaseModalTabs.vue";
import BaseToggleIconButton from "@/new-design/shared/ui/buttons/BaseToggleIconButton.vue"

export default {
    name: 'modalProfitCalculator',
    components: {
        BaseInput, 
        BaseModalTitle,
        BaseModalTabs,
        BaseButton,
        BaseToggleIconButton
    },
    data() {
        return {
            showLeverage: SHOW_LEVERAGE,
            volume: '',
            entryPrice: '',
            stopLoss: '',
            takeProfit: '',
            orderType: false,

            inputContainers: {
                volumeTransparent: false,
                entryPriceTransparent: false,
                stopLossTransparent: false,
                takeProfitTransparent: false,
            },

            results: {
                leverage: 1,
                margin: 0,
                profit: 0,
                loss: 0,
                roe: 0,
                pip: 0,
            }
        }
    },
    methods: {
        activeTabHandler(e){
            switch (e) {
                case '0': 
                    this.switchTo('buy');
                    break;
                case '1': 
                    this.switchTo('sell');
                    break;
            }
        },
        hideModal() {
            this.$store.commit(MODAL, {
                profitCalculator: false
            })
        },
        switchTo(typeString) {
            if (typeString === 'buy') {
                this.orderType = false;
            }
            if (typeString === 'sell') {
                this.orderType = true;
            }
        },
        calculate() {
            this.results.leverage = this.symbol.leverage_default || this.activeAccount.leverage

            let margin = this.volume / this.results.leverage,
                point = this.volume * Math.pow(0.1, this.symbol.precision)

            let baseRate = 1, baseCur = this.symbol.base_currency,
                accRate = 1, accCur = this.activeAccount.currency

            if (this.symbols["USD" + baseCur] !== undefined) {
                baseRate = this.symbols["USD" + baseCur].quote.bid
            } else if (this.symbols[baseCur + "USD"] !== undefined) {
                baseRate = 1 / this.symbols[baseCur + 'USD'].quote.bid
            }

            if (this.symbols["USD" + accCur] !== undefined) {
                accRate = this.symbols["USD" + accCur].quote.bid
            } else if (this.symbols[accCur + "USD"] !== undefined) {
                accRate = 1 / this.symbols[accCur + 'USD'].quote.bid
            }

            let marginRate = accRate / baseRate
            point *= marginRate
            let quoteBid = this.symbol?.quote?.bid || 1

            this.results.margin = (margin * quoteBid * marginRate).toFixed(this.activeAccount.precision) || 0
            this.results.profit = (point * (this.orderType ? this.entryPrice - this.takeProfit : this.takeProfit - this.entryPrice) * Math.pow(10, this.symbol.precision)).toFixed(this.activeAccount.precision) || 0
            this.results.loss = (point * (this.orderType ? this.entryPrice - this.stopLoss : this.stopLoss - this.entryPrice) * Math.pow(10, this.symbol.precision)).toFixed(this.activeAccount.precision) || 0
            this.results.roe = 100 * this.results.profit / this.results.margin || 0
            this.results.pip = Number(point.toFixed(this.activeAccount.precision) || 0)
        },
        currencySign
    },
    computed: {
        ...mapGetters(['modals', 'symbols', 'chartActive', 'charts', 'activeAccount', 'tokens']),

        PNL() {
            return 5 * (this.volume - this.entryPrice)
        },
        ROE() {
            return ((this.entryPrice / 1.1) * 0.5) - (this.volume + this.takeProfit - this.stopLoss) || 0
        },
        maxPosition() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                equity: 0,
                margin: 0
            }

            if (!this.activeAccount.id) {
                return 0
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            for (let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                b.margin += o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision)) : 0
            }

            let freeMargin = b.balance + b.credit + b.profit - b.margin,
                leverage = this.symbol.leverage_default || this.activeAccount.leverage,
                position = freeMargin * leverage,
                quoteBid = this.symbol?.quote?.bid || 1

            if (this.symbol.base_currency === this.activeAccount.currency) {
                position /= quoteBid
            } else if (this.symbols[this.symbol.base_currency + this.activeAccount.currency] !== undefined) {
                position /= quoteBid * this.symbols[this.symbol.base_currency + this.activeAccount.currency].quote.bid
            } else if (this.symbols[this.activeAccount.currency + this.symbol.base_currency] !== undefined) {
                position /= quoteBid / this.symbols[this.activeAccount.currency + this.symbol.base_currency].quote.bid
            }

            if (this.symbol.lot_step && this.symbol.lot) {
                let step = (this.symbol.lot_step * this.symbol.lot)
                position = Math.floor(position / step) * step
            }

            return position
        },
        symbol() {
            let symbol = {
                alias: ''
            }

            if (!this.chartActive || !this.charts[this.chartActive]) {
                return symbol
            }
            return this.symbols[this.charts[this.chartActive].symbol] || symbol
        },
        isInputsEmpty(){
            return this.volume + this.entryPrice + this.stopLoss + this.takeProfit
        },
    },
    watch: {
        modals: {
            deep: true,
            handler(val) {
                if (val.profitCalculator) {
                    this.orderType = false

                    this.volume = this.symbol.lot
                    this.entryPrice = this.symbol?.quote?.bid || 1
                    this.stopLoss = (this.entryPrice - 100 * Math.pow(0.1, this.symbol.precision)).toFixed(this.symbol.precision)
                    this.takeProfit = (this.entryPrice + 100 * Math.pow(0.1, this.symbol.precision)).toFixed(this.symbol.precision)

                    this.calculate()
                }
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    position: absolute;
    z-index: 200;
}

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    /*grid-template-columns: repeat(1fr);*/
    background: var(--background-dark);
    z-index: 200;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: 110px;
    width: 360px;
    border-radius: 8px;
    background: var(--System-Surface3);
}

.title-wrapper {
    padding: 18px 12px;
    color: var(--System-OnSurface);
}

.title-wrapper i {
    cursor: pointer;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrow-icon {
    display: none
}

.inner{
    padding: 18px 12px;
}

.inner-inputs {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-gap: 16px;
}

.max-position {
    color: var(--System-Outline);
    padding: 16px 0;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.currency {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    grid-gap: 10px;
    color: var(--System-OnSurface);
}
.currency div {
    zoom: .3;
}

.currency span {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    display: block;
}

.actions button {
    cursor: pointer;
}

.results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding-top: 24px;
}

.item {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    border-radius: 4px;
    background: rgba(64, 70, 89, 0.16);
}

.item-name {
    color: var(--System-OnSurfaceVariant);
}

.item-value {
    color: var(--System-OnSurface);
}

.item-value > span {
    color: var(--System-Outline);
}

@media (max-width: 767px) {
    .modal{
        width: 100%;
        height: 100%;
        padding: 0 10px;
        background: var(--System-Surface);
    }
    
    .title {
        flex-direction: column-reverse;
        align-items: start;
        gap: 16px;
    }

    .title h4 {
        font-size: 22px;
    }

    .cross-icon {
        display: none
    }

    .arrow-icon {
        display: block;
        margin-right: 15px;
    }
}

</style>
