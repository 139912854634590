<template>
    <button @click="$emit('update')">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.5" viewBox="0 0 18 17" fill="none">
            <path d="M11.5711 11.2427L11.518 9.2451H7.91179L14.7426 2L13.2426 0.5L6.49758 7.83089L6.49758 4.22465L4.5 4.17161V11.2427H11.5711Z" fill="#93D5C6"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 17V15H0L5.96046e-08 17H18Z" fill="#93D5C6"/>
        </svg>
    </button>
</template>

<script>
export default {
    name: "DepositAccountIconButton"
}
</script>

<style scoped>
button {
    color: var(--System-Tertiary);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 4px;
    cursor: pointer;
    background: var(--System-Surface2)
}

button:hover {
    background: var(--Statelayers-tertiary-opacity08);
}

button:focus-visible {
    background: var(--Statelayers-tertiary-opacity12);
}

button:active {
    background: var(--Statelayers-tertiary-opacity16);
}

button:disabled {
    background: transparent;
    color: var(--System-OnSurface);
}

button svg path {
    fill: var(--System-Tertiary);
}
</style>
