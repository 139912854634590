<template>
    <div class="wrapper">
        <div v-if="title" class="title body__medium"> {{ title }}</div>
        <div class="input-box">
            <div class="box">
                <input 
                    v-model="baseInput" 
                    :placeholder="placeholder" 
                    :class="{ 'no-valid': error }"
                    :readonly="readonly"
                    class="body__large" 
                    :style="$slots.icon ? 'padding-left: 45px' : ''"
                    maxlength="10"
                    @input="updateInputValue" />
                <div v-if="baseInput && !readonly" class="icons" @click="clearInputValue">
                    <BaseToggleSmallButton  
                        v-if="value && type !== 'password'" 
                        @click.native="clearInputValue">
                            <template #icon>
                                <i class="material-symbols-outlined">close</i>
                            </template>
                    </BaseToggleSmallButton>
                </div>
                <div class="slot-wrapper body__large">
                    <slot name="icon" />
                </div>
            </div>
        </div>
        <small v-if="info">{{ info }}</small>
    </div>
</template>

<script>
import BaseToggleSmallButton from "@/new-design/shared/ui/buttons/BaseToggleSmallButton.vue"

export default {
    name: "BaseNumberInput",
    components: {BaseToggleSmallButton},
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        error: {
            type: Boolean,
            default: false
        },
        info: {
            type: String,
            default: ''
        },
        validateFunction: {
            type: Function,
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            baseInput: this.value
        }
    },
    methods: {
        updateInputValue(e) {
            if (this.validateFunction && e) {
                e.target.value = this.validateFunction(e.target.value)
                this.baseInput = this.validateFunction(e.target.value)
            }

            if (this.type === 'number' && e) {
                this.baseInput = this.validateNumberInput(e.target.value)
            }

            this.$emit('input', this.baseInput);
        },
        validateNumberInput(e) {
            e = e.replace(/[^0-9.,]/g, '') // Разрешает только цифры, точки и запятые
                .replace(/(\..*)\./g, '$1') // Убирает лишние точки
                .replace(/(,.*),/g, '$1') // Убирает лишние запятые
                .replace(/(,.*)\./g, '$1') // Убирает точку, если уже есть запятая
                .replace(/(\..*),/g, '$1'); // Убирает запятую, если уже есть точка

            if (((e.match(/\./g) || []).length > 1) || ((e.match(/,/g) || []).length > 1)) {
                e = this.validateNumberInput(e)
            }

            return e
        },
        clearInputValue() {
            this.baseInput = '';
            this.updateInputValue();
        }
    },
    watch: {
        value(val) {
            this.baseInput = val;
        }
    },
}
</script>

<style scoped>
.wrapper {
    width: 100%;
}

.input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.title {
    display: flex;
    padding-bottom: 4px;
}

.box {
    display: flex;
    align-items: center;
    width: 100%;
}

input {
    padding: 10px 32px 10px 8px;
    outline: none;
    height: 100%;
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
}

input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--System-Outline);
}

input:focus {
    border-color: var(--System-Primary);
}

.icons {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}

.icons i {
    color: var(--System-OnSurfaceVariant);
}

.no-valid {
    border-color: var(--System-Error);
}

.slot-wrapper{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 32px;
    overflow: hidden;
}
</style>

