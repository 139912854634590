<template>
    <div class="modal-wrapper" :class="{hide: !modals.withdrawalCancel}" v-on:click.stop="hideModal">
        <div class="modal ElevationDark__3" v-on:click.stop>
            <div class="title headline__small">
                Confirm Withdrawal Cancellation
            </div>
            <div class="text label__largeprominent">
                Are you sure you want to cancel your withdrawal request?
            </div>

            <div class="buttons">
                    <BaseButton :buttonText="'Close'" :buttonStyle="'text-primary'" @click.native="hideModal" />
                    <BaseButton :buttonText="'Confirm'" :buttonStyle="'filled-primary'" @click.native="cancelWithdrawal(modalWithdrawalCancel.id)" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL} from '@/store/mutations.type'
import {CANCEL_WITHDRAWAL} from '@/store/actions.type'
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"

export default {
    name: "WithdrawalCancelModal",
    components: {BaseButton},
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                withdrawalCancel: false
            })
        },
        cancelWithdrawal(id) {
            this.$store.commit(MODAL, {
                withdrawalCancel: false
            })

            this.$store.dispatch(CANCEL_WITHDRAWAL, id)
        },
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'modalWithdrawalCancel']),
        activeOrder() {
            return this.activeAccount.orders[this.modalClosePosition.activeOrderId] || {
                alias: '',
                type: 0,
                volume: 0
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 25px;
    z-index: 1100;
    border-radius: 8px;
    background: var(--System-Surface3);

}

.title {
    margin-bottom: 16px;
    color: var(--System-OnSurface);
}

.text {
    color: var(--System-OnSurfaceVariant);
    margin-bottom: 24px;
}

.buttons {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}
</style>
