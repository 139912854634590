<script>
import toggler from '@/new-design/shared/mixins/toggler'

export default {
    name: "BaseToggleIconButton",
    props: {
        buttonStyle: {
            type: String
        }
    },
    mixins: [toggler]
}
</script>

<template>
    <button
        class="toggle-icon-button"
        :class="[buttonStyle, {active: state}]"
        @click.stop="toggler()"
    >
        <span class="state-layer">
            <slot v-if="!state" name="init-icon"/>
            <slot v-else name="active-icon"/>
            <slot v-if="$slots['icon']" name="icon"/>
        </span>
    </button>
</template>

<style scoped>
.toggle-icon-button {
    border-radius: var(--Corner-Extra-small);
    cursor: pointer;
}

.state-layer {
    display: inline-flex;
    padding: 6px;
    border-radius: var(--Corner-Extra-small);
}

.toggle-icon-button.filled {
    background: var(--System-Surface5);
}

.toggle-icon-button.filled path {
    fill: var(--System-Primary);
}

.toggle-icon-button.filled {
    color: var(--System-Primary);
}

.toggle-icon-button.filled:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.toggle-icon-button.filled:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.toggle-icon-button.filled:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.toggle-icon-button.filled.active:active .state-layer,
.toggle-icon-button.filled:disabled .state-layer  {
    opacity: .38;
    background: var(--Statelayers-onsurface-opacity12);
}

.toggle-icon-button.filled.active {
    background: var(--System-Primary);
}

.toggle-icon-button.filled.active path {
    fill: var(--System-OnPrimary);
}

.toggle-icon-button.filled.active {
    color: var(--System-OnPrimary);
}

.toggle-icon-button.filled.active:hover .state-layer {
    background: var(--Statelayers-onprimary-opacity08);
}

.toggle-icon-button.filled.active:focus-visible .state-layer {
    background: var(--Statelayers-onprimary-opacity12);
}

.toggle-icon-button.filled.active:active .state-layer {
    background: var(--Statelayers-onprimary-opacity16);
}

.toggle-icon-button.secondary path {
    fill: var(--System-Secondary);
}

.toggle-icon-button.secondary {
    color: var(--System-Secondary);
}

.toggle-icon-button.secondary.active .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.toggle-icon-button.secondary:hover .state-layer {
    background: var(--Statelayers-secondary-opacity08);
}

.toggle-icon-button.secondary:focus-visible .state-layer {
    background: var(--Statelayers-secondary-opacity12);
}

.toggle-icon-button.secondary:active .state-layer {
    background: var(--Statelayers-secondary-opacity16);
}

.toggle-icon-button.secondary.active:active .state-layer,
.toggle-icon-button.secondary:disabled .state-layer{
    opacity: .38;
}

.toggle-icon-button.secondary.active path {
    fill: var(--System-Primary);
}

.toggle-icon-button.secondary.active {
    color: var(--System-Primary);
}

.toggle-icon-button.secondary.active:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.toggle-icon-button.secondary.active:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.toggle-icon-button.secondary.active:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.toggle-icon-button.tonal {
    background: var(--System-Surface1);
}

.toggle-icon-button.tonal path {
    fill: var(--System-OnSecondaryContainer);
}

.toggle-icon-button.tonal:hover .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.toggle-icon-button.tonal:focus-visible .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.toggle-icon-button.tonal:active .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity16);
}

.toggle-icon-button.tonal.active:active .state-layer,
.toggle-icon-button.tonal:disabled .state-layer {
    background: var(--Statelayers-onsurface-opacity12);
    opacity: .38;
}

.toggle-icon-button.tonal.active path {
    fill: var(--System-Primary);
    background: var(--System-Surface5);
}

.toggle-icon-button.tonal.active {
    color: var(--System-Primary);
    background: var(--System-Surface5);
}

.toggle-icon-button.tonal.active:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.toggle-icon-button.tonal.active:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.toggle-icon-button.tonal.active:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}

.toggle-icon-button.outlined .state-layer {
    padding: 5px;
}

.toggle-icon-button.outlined {
    border: 1px solid var(--System-Outline);
}

.toggle-icon-button.outlined path {
    fill: var(--System-OnSurface);
}

.toggle-icon-button.outlined:hover .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.toggle-icon-button.outlined:focus-visible .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.toggle-icon-button.outlined:active .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity16);
}

.toggle-icon-button.outlined.active:active .state-layer,
.toggle-icon-button.outlined:disabled .state-layer {
    background: var(--Statelayers-onsurface-opacity12);
    opacity: .38;
}

.toggle-icon-button.outlined.active path {
    fill: var(--System-Primary);
    background: var(--System-Surface5);
}

.toggle-icon-button.outlined.active {
    color: var(--System-Primary);
    background: var(--System-Surface5);
}

.toggle-icon-button.outlined.active:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.toggle-icon-button.outlined.active:focus-visible .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.toggle-icon-button.outlined.active:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}
</style>
