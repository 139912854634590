<template>
    <div class="chat-bar">
        <BaseIconButton class="attach">
            <template #icon>
                <label for="file">
                    <i class="material-symbols-outlined">attach_file</i>
                </label>
            </template>
        </BaseIconButton>
        <div class="message-box">
            <textarea maxlength="524288" :placeholder="`${tokens.fullScreenSupport.sendMessage}`" id="support-message"
                      class="body__medium"
                      v-model="message" @keyup.enter="sendMessage"/>
        </div>
        <div class="send">
            <input type="file" id="file" ref="file" @change="loadPreview()">
            <BaseIconButton @click.native="sendMessage">
                <template #icon>
                    <i class="material-symbols-outlined">send</i>
                </template>
            </BaseIconButton>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {NOTIFICATION, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {ATTACHMENT_UPLOAD, SUPPORT_SEND} from "@/store/actions.type"
import {NEW_CHAT, REST_ENDPOINT} from "@/common/config"
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue";

export default {
    name: "ChatBar",
    components: {BaseIconButton},
    props: {
        placeholder: {
            type: String
        }
    },
    data() {
        return {
            restEndpoint: REST_ENDPOINT,
            message: '',
            ddOver: false,
            toggler: false,
            path: [],
            preview: '',
            previewShow: false,
            typeFile: '',
            newChat: NEW_CHAT
        }
    },
    methods: {
        sendMessage() {
            if (!this.message.trim() && !this.$refs.file.files[0]) {
                return
            }
            // send message
            if (this.user.enable_chat === 0) {
                this.$store.commit(NOTIFICATION_ERROR, this.tokens.errors.CHAT_DISABLED)
                this.message = ''
                this.$refs.file.value = ''
                this.preview = ''
                this.previewShow = false
                return
            }

            if (this.message) {
                this.$store.dispatch(SUPPORT_SEND, this.message)
                this.message = ''
            }

            // send attached file:
            if (this.$refs.file.files[0]) {
                if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(this.$refs.file.files[0].type) > -1) {
                    let data = new FormData()
                    data.append('file', this.$refs.file.files[0])
                    this.$store.dispatch(ATTACHMENT_UPLOAD, data)
                } else {
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
                }
            }

            this.$refs.file.value = ''
            this.preview = ''
            this.previewShow = false
        },
        copy(text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Message copied to clipboard')
                })
        },
        over(val) {
            this.ddOver = val
        },
        loadPreview() {
            let file = this.$refs.file.files[0]
            this.typeFile = file.type
            if (['image/jpeg', 'image/png'].indexOf(this.typeFile) > -1) {
                this.preview = URL.createObjectURL(file);
                this.previewShow = true
            } else if (['application/pdf'].indexOf(this.typeFile) > -1) {
                this.typeFile = file.type
                this.preview = ''
                this.previewShow = true
            } else {
                this.preview = ''
                this.preview = false
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
            }
        },
    },
    computed: {
        ...mapGetters(['supportMessages', 'modalFullscreen', 'user', 'tokens', 'staticBase'])
    },
    watch: {
        supportMessages() {
            setTimeout(() => {
                let container = document.getElementById("support-messages")
                container.scrollTop = container.scrollHeight
            }, 300)
        },
        modalFullscreen: {
            deep: true,
            handler(val) {
                if (val.page === 'support') {
                    setTimeout(() => {
                        let container = document.getElementById("support-messages")
                        container.scrollTop = container.scrollHeight
                    }, 300)
                }
            }
        }
    },
}
</script>

<style scoped>
.chat-bar {
    background: var(--System-Surface1);
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    border-top: var(--System-Surface5);
    padding: 16px;
    place-items: center stretch;
}

.message-box {
    margin: 0 10px;
}

textarea {
    width: 100%;
    height: 52px;
    font-family: inherit;
    resize: none;
    border-radius: var(--Corner-small);
    background: var(--System-Background);
    color: var(--System-Outline);
    padding: 16px;
    outline: none;
    border: none;
}

textarea::placeholder {
    color: var(--System-Outline);
}

.send {
    padding: 6px;
    cursor: pointer;
}

#file {
    display: none;
}

.attach {
    height: auto;
}

.attach label {
    cursor: pointer;
}
</style>
