<template>
    <div class="tabs" @click="updateActiveTab">
        <div v-for="(tab, idx) in tabs"
            :key="idx" class="item title__medium"
            :class="{ active: tab === tabs[activeTab] }"
            :data-index="idx"
            v-ripple
        >
            {{ tab }}
            <input
                type="radio"
                name="tab"
                :checked="tab === tabs[activeTab]"
                :data-index="idx"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseModalTabs",
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        active: {
            type: [String,Number],
            default: 0
        }
    },
    data() {
        return {
            activeTab: this.active,
        }
    },
    methods: {
        updateActiveTab(e) {
            let activeTabIndex = e.target.getAttribute('data-index')
            if (activeTabIndex) {
                this.activeTab = activeTabIndex;
                this.$emit('updateActiveTab', activeTabIndex);
            }
        },
    }
}
</script>

<style scoped>
.tabs {
    display: grid;
    grid-template-columns: minmax(0, 50%) minmax(0, 50%);
    border-bottom: 1px solid #2C334099
}

input[type="radio"] {
    opacity: 0;
    z-index: -10;
}

.item {
    width: 100%;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--System-OnSurfaceVariant);
    border-bottom: 1px solid var(--System-Surface5, #252B36);
    transition: all .3s ease;
}

.item::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--System-Primary);
    opacity: 0;
    transition: all .3s ease;
}

.item.active {
    color: var(--System-Primary);
}

.item.active::after {
    opacity: 1;
}

.item:focus-within {
    background: var(--Statelayers-onsurface-opacity12);
    color: var(--System-OnSurface);
}

.item.active:focus-within {
    background: var(--Statelayers-primary-opacity12);
}

@media (hover:hover) {
    .item:hover {
        background: var(--Statelayers-onsurface-opacity08);
        color: var(--System-OnSurface);
    }

    .item.active:hover {
        background: var(--Statelayers-primary-opacity08);
    }
}
</style>
