<script>
export default {
    name: "BaseInfoIcon"
}
</script>

<template>
    <div class="info-icon">
        <i class="material-symbols-outlined info">info_i</i>
    </div>
</template>

<style scoped>
.info-icon {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--Statelayers-onsurfacevariant-opacity08);
}

.info-icon i {
    color: var(--System-Outline);
    font-size: 14px;
}

.info-icon:hover {
    background-color: var(--Statelayers-primary-opacity08);
}

.info-icon:active {
    background-color: var(--Statelayers-primary-opacity16);
}

.info-icon:hover i {
    color: var(--System-Primary);
}
</style>
