<template>

    <div class="saving-success">
        <div class="saving-success-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="47" height="48" viewBox="0 0 47 48" fill="none">
                <g filter="url(#filter0_b_1420_24020)">
                    <path
                        d="M23.5003 0.666992C10.6437 0.666992 0.166992 11.1437 0.166992 24.0003C0.166992 36.857 10.6437 47.3337 23.5003 47.3337C36.357 47.3337 46.8337 36.857 46.8337 24.0003C46.8337 11.1437 36.357 0.666992 23.5003 0.666992ZM34.6537 18.6337L21.4237 31.8637C21.097 32.1903 20.6537 32.377 20.187 32.377C19.7203 32.377 19.277 32.1903 18.9503 31.8637L12.347 25.2603C11.6703 24.5837 11.6703 23.4637 12.347 22.787C13.0237 22.1103 14.1437 22.1103 14.8203 22.787L20.187 28.1537L32.1803 16.1603C32.857 15.4837 33.977 15.4837 34.6537 16.1603C35.3303 16.837 35.3303 17.9337 34.6537 18.6337Z"
                        fill="url(#paint0_linear_1420_24020)" />
                </g>
                <defs>
                    <filter id="filter0_b_1420_24020" x="-61.083" y="-60.583" width="169.167" height="169.167"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="30.625" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1420_24020" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1420_24020"
                            result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_1420_24020" x1="-0.299902" y1="8.6001" x2="38.4405" y2="54.689"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4A7EFF" />
                        <stop offset="0.486792" stop-color="#61BEDA" />
                        <stop offset="1" stop-color="#404C73" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
        <div class="saving-success-title title__medium">Savings account created successfully</div>

        <div class="trasfer-details label__mediumprominent">
            <label>Transfer details</label>
            <div class="trasfer-details-inner">
                <div>
                    <span>Account</span>
                    <span>{{accounts[account.account_from].group.toUpperCase()}} Account </span>
                </div>
                <div>
                    <span>Currency</span>
                    <span>{{ accounts[account.account_from].currency }}</span>
                </div>
                <div>
                    <span>Transfer amount</span>
                    <span>{{ account.balance }}</span>
                </div>
            </div>
        </div>
        <div class="investment-details label__mediumprominent">
            <label>Investment details</label>
            <div class="investment-details-inner">
                <div>
                    <span>Currency</span>
                    <span>{{ account.currency }}</span>
                </div>
                <div>
                    <span>Investment</span>
                    <span>{{ account.balance }}</span>
                </div>
                <div>
                    <span>Period</span>
                    <span>{{ tokens.fullScreenSavings['pct_'+account.period]}}</span>
                </div>
                <div>
                    <span>Created</span>
                    <span>{{ timeToString(account.created*1000).split(' ')[0] }} </span>
                </div>
                <div>
                    <span>Release time</span>
                    <span>
                        {{ account.period > 0 ?
                            timeToString((account.created * 1000) + (account.period * 24 * 3600 * 1000)).split(' ')[0] : '-'}}
                    </span>
                </div>
            </div>
        </div>

        <div class="current-time label__mediumprominent">
            {{ tokens.fullScreenSavings.currentTime }}
            {{new Date(time).toLocaleString()}}
        </div>

        <div class="saving-success-buttons">
            <BaseButton
                :buttonText="'View Savings Accounts'"
                :buttonStyle="'outlined-primary'"
                @click.native="$emit('openSavingsAccountsTab')" />
            <BaseButton
                :buttonText="'Continue Investment'"
                :buttonStyle="'filled-primary'"
                @click.native="$emit('close')"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {currencySign, timeToString} from "@/common/helpers"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton"

export default {
    name: "SavingSuccess",
    components: {BaseButton},
    props: {
        account : {
            type: Object
        },
        time: {
            type: [Number, String]
        },
    },
    computed: {
        ...mapGetters(['tokens', 'symbols', 'accounts']),
    },
    methods: {
        currencySign,
        timeToString
    },
}
</script>

<style scoped>

.saving-success {
    position: absolute;
    inset: 0;
    background: var(--System-Surface);
    z-index: 88888888;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.saving-success-icon {
    padding: 12px;
    border-radius: 1000px;
    background: var(--Statelayers-onsurfacevariant-opacity08, rgba(227, 226, 233, 0.08));
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
 }

.saving-success-title {
    color: var(--System-OnSurfaceVariant);
    text-align: center;
    margin-bottom: 8px;
 }

.trasfer-details,
.investment-details {
    width: 100%;
    padding: 0 16px;
}

.trasfer-details > label,
.investment-details > label {
    display: block;
    margin-bottom: 8px;
    margin-top: 24px;
}

.trasfer-details-inner,
.investment-details-inner{
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
 }

.trasfer-details-inner > div,
.investment-details-inner > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.current-time {
    margin-top: 24px;
    width: 100%;
    padding: 0 16px
 }

.saving-success-buttons {
    width: 100%;
    margin-top: auto;
    padding: 24px 16px;
    display: flex;
    gap: 8px;
    border-top: 1px solid var(--System-OutlineVariant);
    button {
        width: 100%;
    }
}

@media (max-width: 1023px) {
    .saving-success-buttons button:last-child {
        display: none;
    }
}
</style>
