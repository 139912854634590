<template>
    <div class="new-design" :class="{mobile: isMobile}" :style="!lightTheme ? baseLightTheme : baseDarkTheme">
        <div class="blur-container" :class="{blur: blur}">
            <BaseBannerInfo  v-if="!modals.fullscreen && alertMessage" :message="alertMessage"/>
            <TheHeader/>
            <div class="main">
                <SideMenu/>
                <SideMenuBurger/>
                <HamburgerMenu :class="{hide: !isMobile}"/>
                <MenuModal/>
                <div class="bigPane">
                    <SymbolsPanel/>
                    <Grid/>
                    <OrdersPane/>
                    <StatusBar/>
                    <FooterButtons/>
                </div>
            </div>
            <Footer/>
        </div>
        <LoginModal v-if="!['tradingviewonline'].includes(brand)"/>
        <CustomLoginModals v-else/>
        <ClosePositionModal/>
        <NoLandscape/>
        <ProfitCalculatorModal/>
        <RegisterModal/>
        <AccountModal/>
        <SymbolsModal/>
        <BalanceModal/>
        <AccountModal/>
        <GridsModal/>
        <NoConnectionModal/>
        <AutoCloseModal/>
        <PendingModal/>
        <NotificationsList/>
        <ForgotPasswordModal/>
        <NewPasswordModal/>
        <ClosePositionModal/>
        <CancelPositionModal/>
        <DepositSuccessModal/>
        <DepositFailModal/>
        <WithdrawalCancelModal/>
        <Fullscreen/>
        <AutoTraderModal/>
        <PciDssModal />
        <QrCodeModal />
        <TimePickerModal/>
        <AlertMessageModal v-if="alertMessage" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {isMobile} from '@/common/helpers'

import MenuModal from '@/new-design/features/SideMenu/ui/MenuModal.vue'
import HamburgerMenu from '@/new-design/not-sorted/Modals/HamburgerMenu'
import Grid from '@/new-design/not-sorted/Grid.vue'
import OrdersPane from "@/new-design/not-sorted/new/OrdersPane.vue"
import StatusBar from "@/new-design/not-sorted/new/StatusBar.vue"

import {
    BRAND,
    CUSTOM_LOGIN_MODAL
} from "@/common/config"
import LoginModal from "@/new-design/not-sorted/new/LoginModal/LoginModal"
import SideMenu from "@/new-design/features/SideMenu/ui/SideMenu.vue"
import SideMenuBurger from "@/new-design/features/SideMenu/ui/SideMenuBurger.vue"
import TheHeader from "@/new-design/widgets/TheHeader.vue"
import '@/new-design/app/styles/index.css'
import {darkTheme} from "@/new-design/app/theme/darkTheme"
import {lightTheme} from "@/new-design/app/theme/lightTheme"
import ClosePositionModal from "@/new-design/features/Modals/ClosePosition/ui/ClosePositionModal.vue"
import AutoCloseModal from "@/new-design/not-sorted/new/Modals/AutoCloseModal.vue"
import BalanceModal from "@/new-design/not-sorted/new/Modals/BalanceModal.vue"
import GridsModal from "@/new-design/not-sorted/Modals/GridsModal.vue"
import RegisterModal from "@/new-design/not-sorted/new/LoginModal/RegisterModal.vue"
import AlertMessageModal from "@/new-design/not-sorted/Modals/AlertMessageModal.vue"
import NewPasswordModal from "@/new-design/not-sorted/Modals/NewPasswordModal.vue"
import AccountModal from "@/new-design/not-sorted/Modals/AccountModal.vue"
import ProfitCalculatorModal from "@/new-design/not-sorted/Modals/ProfitCalculatorModal.vue"
import NoConnectionModal from "@/new-design/not-sorted/Modals/NoConnectionModal.vue"
import CustomLoginModals from "@/new-design/not-sorted/CustomLoginModals.vue"
import DepositSuccessModal from "@/new-design/not-sorted/Modals/DepositSuccessModal.vue"
import QrCodeModal from "@/new-design/not-sorted/Modals/QrCodeModal.vue"
import NoLandscape from "@/new-design/not-sorted/Modals/NoLandscape.vue"
import PciDssModal from "@/new-design/not-sorted/Modals/PciDssModal.vue"
import TimePickerModal from "@/new-design/not-sorted/Modals/TimePickerModal.vue"
import Fullscreen from "@/new-design/not-sorted/Modals/Fullscreen.vue"
import WithdrawalCancelModal from "@/new-design/not-sorted/Modals/WithdrawalCancelModal.vue"
import DepositFailModal from "@/new-design/not-sorted/Modals/DepositFailModal.vue"
import ForgotPasswordModal from "@/new-design/not-sorted/Modals/ForgotPasswordModal.vue"
import AutoTraderModal from "@/new-design/features/Modals/Autotrader/ui/AutoTraderModal.vue"
import PendingModal from "@/new-design/not-sorted/Modals/PendingModal.vue"
import SymbolsModal from "@/new-design/features/Modals/AddSymbol/ui/SymbolsModal.vue"
import CancelPositionModal from "@/new-design/features/Modals/CanselOrder/ui/CancelPositionModal.vue"
import SymbolsPanel from "@/new-design/widgets/SymbolsPanel.vue"
import FooterButtons from '@/new-design/not-sorted/new/FooterButtons.vue'
import BaseBannerInfo from "@/new-design/shared/ui/banners/BaseBannerInfo.vue"
import NotificationsList from "@/new-design/features/Notifications/NotificationsList/ui/NotificationsList.vue";

export default {
    name: 'NewDesign',
    components: {
        NotificationsList,
        BaseBannerInfo,
        SymbolsPanel,
        CancelPositionModal,
        SymbolsModal,
        PendingModal,
        AutoTraderModal,
        ForgotPasswordModal,
        DepositFailModal,
        WithdrawalCancelModal,
        Fullscreen,
        TimePickerModal,
        PciDssModal,
        NoLandscape,
        QrCodeModal,
        DepositSuccessModal,
        CustomLoginModals,
        NoConnectionModal,
        ProfitCalculatorModal,
        AccountModal,
        NewPasswordModal,
        AlertMessageModal,
        RegisterModal,
        GridsModal,
        BalanceModal,
        AutoCloseModal,
        ClosePositionModal,
        TheHeader,
        SideMenu,
        SideMenuBurger,
        LoginModal,
        StatusBar,
        OrdersPane,
        Grid,
        MenuModal,
        HamburgerMenu,
        FooterButtons
    },
    beforeMount() {
        this.updateStyles();
    },
    data() {
        return {
            newPlatformDesign: true,
            brand: BRAND,
            baseLightTheme: darkTheme,
            baseDarkTheme: lightTheme,
            customLoginModal: CUSTOM_LOGIN_MODAL,
            sessionStateInit: false,
            isMobile: isMobile(),
        }
    },
    methods: {
        updateStyles() {
            const oldDesignLink = document.querySelector('link.old-design-style')
            const newDesignLink = document.querySelector('link.new-design-style')

            if (oldDesignLink) {
                oldDesignLink.remove()
            }
            if (!newDesignLink) {
                this.addStyleSheet('../designs/new-design/general.css', 'new-design-style')
            }
        },
        addStyleSheet(href, className) {
            const linkElement = document.createElement('link')
            linkElement.rel = 'stylesheet'
            linkElement.href = href
            linkElement.className = className

            document.head.appendChild(linkElement)
        },
    },
    computed: {
        ...mapGetters(['blur', 'symbols', 'sessionsState', 'staticBase', 'lightTheme', 'variables', 'modals']),
        alertMessage() {
            return this.variables.ALERT_MESSAGE ? this.variables.ALERT_MESSAGE.trim() : false
        }
    },
}
</script>

<style>

</style>

