<script>
export default {
    name: "BaseDepositButton"
}
</script>

<template>
    <button class="deposit-button">
        <div class="state-layer">
            <span class="label">Deposit</span>
            <svg class="icon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5711 15.7427L14.518 13.7451H10.9118L17.7426 6.5L16.2426 5L9.49758 12.3309L9.49758 8.72465L7.5 8.67161V15.7427H14.5711Z" fill="#003930"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21 21.5V19.5H3L3 21.5H21Z" fill="#003930"/>
            </svg>
        </div>
    </button>
</template>

<style scoped>
.deposit-button {
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Tertiary);
    color: var(--System-OnTertiary);
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.deposit-button .state-layer {
    padding: 8px 16px;
}

.deposit-button:hover .state-layer {
    background: var(--Statelayers-ontertiary-opacity08, rgba(0, 57, 48, 0.08));
}

.deposit-button:focus-visible .state-layer {
    background: var(--Statelayers-ontertiary-opacity12, rgba(0, 57, 48, 0.08));
}

.deposit-button:active .state-layer {
    background: var(--Statelayers-ontertiary-opacity16, rgba(0, 57, 48, 0.08));
}

.icon {
    display: none;
}

.deposit-button svg path {
    fill: var(--System-OnTertiary);
}

@media (max-width: 767px) {
    .deposit-button .state-layer {
        padding: 6px;
    }

    .icon {
        display: block;
    }

    .label {
        display: none;
    }
}
</style>
