<template>
    <BasePanel class="crypto">
        <template #content>
            <h5 class="title title__medium">Choose a Cryptocurrency Wallet</h5>
            <div class="cryptocurrencies">
                <BaseSmallChip
                    v-for="crypto in pspListManual"
                    :key="crypto.alias"
                    :text="crypto.text"
                    :class="{active: active.text === crypto.text}"
                    @click.native="changeTab(crypto)"
                >
                    <template #icon>
                        <img width="18" height="18" :src="staticBase+`currencies/${crypto.symbol}.png`" alt="">
                    </template>
                </BaseSmallChip>
            </div>
            <div class="address-viewer">
                <div class="title-view">
                    <h4 class="sub-title body__medium">Deposit Address</h4>
                    <p class="desc body__small">Scan the QR code or copy the deposit address</p>
                </div>
                <div class="qr-pad">
                    <div v-if="address" class="wallet-address">
                        <div class="qr" @click="openQrCodeModal(address)">
                            <QrcodeVue :value="address" :size="100"/>
                        </div>
                        <div class="info">
                            <div class="wallet">
                                <p class="desc body__medium">{{ active.text }} Wallet</p>
                                <p class="desc body__small">{{ address }}</p>
                            </div>
                            <BaseButtonSmall
                                @click.native="copyAddress(address)"
                                :button-text="'Copy Deposit Address'"
                                :icon="'content_copy'"
                                :button-style="'outlined-primary'"
                            />
                        </div>
                    </div>
                    <div v-else class="desc-info">
                        <p class="body__medium wallet-info">{{ tokens.fullScreenDeposit.addWalletAddress }}</p>
                    </div>
                </div>
            </div>
        </template>
    </BasePanel>
</template>

<script>
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import BaseSmallChip from "@/new-design/shared/ui/chips/BaseSmallChip.vue"
import {manualPspList} from "@/common/manual-psp-list"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"
import QrcodeVue from "qrcode.vue"
import {MODAL, MODAL_QR_CODE, NOTIFICATION} from "@/store/mutations.type"
import {mapGetters} from "vuex"
import {STATIC_BASE} from "@/common/config";

export default {
    name: "CryptoTab",
    components: {BaseButtonSmall, BaseSmallChip, BasePanel, QrcodeVue},
    data() {
        return {
            active: manualPspList[0],
            pspListManual: manualPspList,
            staticBase: STATIC_BASE,
            address: !this.pspList?.filter(data => data.name !== undefined && data.name === this.active.alias).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.active.alias)[0].wallet,
        }
    },
    computed: {
        ...mapGetters(['pspList', 'tokens'])
    },
    methods: {
        changeTab(tab) {
            this.active = tab
            this.address = !this.pspList.filter(data => data.name !== undefined && data.name === this.active.alias).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.active.alias)[0].wallet
        },
        copyAddress(address) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Address copied to clipboard')
                })
                .catch(() => {
                })
        },
        openQrCodeModal(value) {
            this.$store.commit(MODAL, {
                qrCode: true
            })
            this.$store.commit(MODAL_QR_CODE, value)
        }
    }
}
</script>

<style scoped>
.crypto {
    padding: 16px;
}

.sub-title,
.title {
    color: var(--System-OnSurface);
    text-align: left;
}

.cryptocurrencies {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 0 16px;
    white-space: nowrap;
    flex-wrap: wrap;
}

.title-view {
    padding: 16px 0;
}

.sub-title {
    margin-bottom: 4px;
    text-align: left;
}

.desc {
    color: var(--System-OnSurfaceVariant);
    text-align: left;
}

.qr-pad {
    padding: 16px;
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Surface1);
}

.wallet-address {
    display: flex;
    gap: 16px;
}

.qr {
    padding: 10px;
    border-radius: 2px;
    background: var(--white);
}

.info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.wallet p:first-child {
    margin-bottom: 4px;
}

.button-small {
    height: fit-content;
}

.wallet-info {
    text-align: left;
}

.desc-info {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--System-OnSurfaceVariant);
    width: 100%;
}
</style>
