<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.menuSettings}"
    >
        <div class="modal ElevationLight__2"
             @click.stop
        >
            <div class="title-box">
                <h2 class="title title-large">Settings</h2>
                <i class="close-icon material-symbols-outlined" @click.stop="hideModal">close</i>
            </div>
            <div class="main-content">
                <div class="user-settings">
                    <BaseButton
                        :button-style="'outlined-primary'"
                        :button-text="'Update Password'"
                        @click.native="gotoSettings()"
                    />
                    <BaseButton
                        :button-style="'outlined-primary'"
                        :button-text="user.otp ? 'Disable 2FA protection' : 'Activate 2FA protection'"
                        @click.native="gotoSettings()"
                    />
                </div>
                <PlatformLanguage
                    v-ripple="{color: 'var(--System-SurfaceVariant)', opacity: '.2'}"
                    class="user-item"
                    :select-position="'left'"
                />
                <DashboardCurrency
                    v-ripple="{color: 'var(--System-SurfaceVariant)', opacity: '.2'}"
                    class="user-item"
                    :select-position="'left'"
                />
                <DarkMode class="user-item"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import PlatformLanguage from "@/new-design/features/Settings/PlatformLanguage/ui/PlatformLanguage.vue"
import DashboardCurrency from "@/new-design/features/Settings/DashboardCurrency/ui/DashboardCurrency.vue"
import DarkMode from "@/new-design/features/Settings/DarkMode/ui/DarkMode.vue"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type"

export default {
    name: "MenuSettingsModal",
    components: {BaseButton, DarkMode, DashboardCurrency, PlatformLanguage},
    computed: {
        ...mapGetters(['modals', 'user'])
    },
    methods: {
        gotoSettings() {
            this.hideModal()
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL_FULLSCREEN, {
                page: 'settings',
            })
        },
        hideModal() {
            this.$store.commit(MODAL, {
                menuSettings: false
            })
        }
    }
}
</script>


<style scoped>
.modal-wrapper {
    background: transparent;
}

.modal {
    position: absolute;
    top: 50px;
    right: 75px;
    width: 320px;
    height: 356px;
    background: var(--System-Surface2);
    border-radius: var(--Corner-Extra-small);
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.title {
    color: var(--System-OnSurface);
}

.close-icon {
    color: var(--System-OnSurfaceVariant);
    font-size: 14px;
    display: none;
}

.user-settings {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 12px;
}

.user-item {
    padding: 8px 16px;
    cursor: pointer;
    height: 48px;
}

.user-item:hover {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.user-item:focus-visible,
.user-item:active {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.mobile .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.mobile .close-icon {
    display: block;
}
</style>
