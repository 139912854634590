<template>
    <div class="widget">
        <BaseWidgetTitle :title="tokens.widgetOrders.tradingHistory" @close="hideModal('history')"/>

        <div class="content scroll" ref="history-scroll">
            <div class="no-orders" :class="{hide: history.length}">
                <i class="material-icons">restore</i>
                <span>{{ tokens.widgetOrders.noHistoryLine1 }}<br/>{{ tokens.widgetOrders.noHistoryLine2 }}</span>
            </div>
            <div class="col">
                <div class="history-item"
                     v-for="order in history"
                     :key="order.id"
                     :class="{
                         active: detailsOrder === order.id,
                     }"
                >
                    <div class="history-head">
                        <div class="history-info">
                            <div class="row">
                                <div class="col">
                                    <div class="time body__medium">{{ order.timeStr }}</div>
                                    <div class="lesser">{{ tokens.months[order.month] + ' ' + order.day }}</div>
                                </div>
                            </div>
                            <div>
                                <div class="profit label__large" :class="order.profit > 0 ? 'green' : (order.profit < 0 ? 'red' : '')">{{
                                        order.price_close ? ((order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toLocaleString(undefined, {
                                            minimumFractionDigits: activeAccount.precision,
                                            maximumFractionDigits: activeAccount.precision
                                        })) : tokens.widgetOrders.cancelled.toUpperCase()
                                    }}
                                </div>
                                <div class="lesser">
                                    {{
                                        order && order?.volume !== undefined && order?.volume !== null ?
                                            (order?.volume > 1000 ? order?.volume.toFixed(0) : order?.volume.toFixed(2)) + ' ' + order?.symbol : '-'
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="symbol-info">
                            <div class="row symbol-row body__small" @click="changeSymbol(order.symbol)">
                                <span class="symbol" :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : ''"/>
                                <div class="symbol-container">
                                    <span>
                                        {{
                                            symbols[order.symbol] ? symbols[order.symbol].alias : order.symbol
                                        }}
                                    </span>
                                    <div class="lesser">{{
                                            symbols[order.symbol] ? symbols[order.symbol].market.charAt(0).toUpperCase() + symbols[order.symbol].market.slice(1) : ''
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="price body__small" :class="{hide: !order.price_close}">
                                {{
                                    symbols[order.symbol] && order.price_open ?
                                        order?.price_open?.toLocaleString(undefined, {
                                            minimumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5,
                                            maximumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5
                                        }) : '-'
                                }}
                                →
                                {{
                                    order?.price_close?.toLocaleString(undefined, {
                                        minimumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5,
                                        maximumFractionDigits: symbols[order.symbol] && symbols[order.symbol].precision ? symbols[order.symbol].precision : 5
                                    })
                                }}
                            </div>
                        </div>
                        <div class="details" :class="{hide: detailsOrder !== order.id}">
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.purchaseTime }}</span>
                                <span>{{ new Date(order.time_open * 1000).toLocaleString() }}</span>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.ordersPane.closeTime }}</span>
                                <span>{{ new Date(order.time_close * 1000).toLocaleString() }}</span>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.priceOpen }}</span>
                                <span>
                                {{
                                        order.price_open
                                            ? symbols[order.symbol] && symbols[order.symbol].precision
                                                ? order.price_open.toLocaleString(undefined, {
                                                    minimumFractionDigits: symbols[order.symbol].precision,
                                                    maximumFractionDigits: symbols[order.symbol].precision
                                                })
                                                : order.price_open.toLocaleString()
                                            : '-'
                                    }}
                            </span>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.priceSl }}</span>
                                <div class="row">
                                <span>{{
                                        order.sl ? order.sl.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'
                                    }}</span>
                                </div>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.priceTp }}</span>
                                <div class="row">
                                <span>{{
                                        order.tp ? order.tp.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'
                                    }}</span>
                                </div>
                            </div>
                            <div class="item type">
                                <span>{{ tokens.widgetOrders.orderType }}</span>
                                <BasePositionDirection :type="order.type"/>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.volume }}</span>
                                <span>{{ order.volume }}</span>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.margin }}</span>
                                <span>{{
                                        order.easy_amount ? currencySign(activeAccount.currency) + order.easy_amount.toFixed(activeAccount.precision) : '-'
                                    }}</span>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.pnl }}</span>
                                <span>{{
                                        (order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toFixed(activeAccount.precision)
                                    }}</span>
                            </div>
                            <div class="item body__small">
                                <span>{{ tokens.widgetOrders.commission }}</span>
                                <span>{{
                                        (order.commission > 0 ? '+' : (0 > order.commission ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.commission).toFixed(activeAccount.precision)
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row pointer"
                         v-on:click="detailsOrder = detailsOrder === order.id ? 0 : order.id">
                        {{
                            detailsOrder !== order.id ?  tokens.widgetOrders.show + " " + tokens.widgetOrders.more :  tokens.widgetOrders.show + " " + tokens.widgetOrders.less
                        }} <i
                        class="material-icons">{{
                            detailsOrder !== order.id ? 'arrow_drop_down' : 'arrow_drop_up'
                        }}</i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_MENU, SYMBOL_ACTIVE_UPDATE} from '@/store/mutations.type'
import {currencySign, isMobile} from '@/common/helpers'
import BaseWidgetTitle from "@/new-design/shared/ui/titles/BaseWidgetTitle.vue";
import BasePositionDirection from "@/new-design/shared/ui/panels/BasePositionDirection.vue";

export default {
    name: 'OrdersHistory',
    components: {BasePositionDirection, BaseWidgetTitle},
    mounted() {
        setInterval(() => {
            if (!this.$refs['history-scroll']) {
                return
            }

            if (this.$refs['history-scroll'].scrollTop + this.$refs['history-scroll'].clientHeight === this.$refs['history-scroll'].scrollHeight && this.modalMenu.history && this.activeAccount.history.length > this.historyPage * 20) {
                this.historyPage++
            }
        }, 1000)
    },
    data() {
        return {
            historyPage: 1,
            detailsOrder: 0
        }
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        currencySign
    },
    computed: {
        ...mapGetters(['modals', 'symbols', 'tokens', 'chartActive', 'activeAccount', 'modalMenu', 'staticBase']),
        history() {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const history = []

            if (!this.activeAccount.history) {
                return history
            }

            const startIndex = 0
            const endIndex = this.historyPage * 20
            const slicedHistory = this.activeAccount.history.slice(startIndex, endIndex)

            slicedHistory.forEach((order) => {
                const d = new Date(order.time_close * 1000)

                order.timeStr = ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2)
                order.dayStr = monthNames[d.getMonth()] + ' ' + ('0' + d.getDate()).slice(-2)
                order.month = monthNames[d.getMonth()]
                order.day = ('0' + d.getDate()).slice(-2)

                order.amount = order.easy_amount || order.margin

                history.push(order)
            })

            return history
        },
    },
    watch: {
        activeAccount() {
            this.historyPage = 1
            this.$refs['history-scroll'].scrollTo(0, 0)
        }
    }
}
</script>

<style scoped>
.content {
    padding: 12px;
    border-top: 1px solid var(--System-OutlineVariant);
}

.history-info {
    display: flex;
    justify-content: space-between;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
}

.details .close-position {
    margin-bottom: 10px;
    padding: 4px;
}

.details .item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    background-color: inherit;
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 8px;
    overflow: initial;
}

.history-item {
    border: 1px solid var(--System-Surface5);
    border-radius: var(--Corner-small);
    margin-bottom: 16px;
}

.details .item span:last-child {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
}

.details .item span:last-child i {
    font-size: 16px;
}

.price {
    color: var(--System-OnSurface);
}

.history-head {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    flex-direction: column;
}

.symbol-row {
    align-items: center;
}

.symbol-info {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.symbol-container {
    display: flex;
    flex-direction: column;
}

.history-item.active .row.pointer {
    background: var(--Statelayers-surface5-opacity16);
}

.row.pointer {
    align-items: center;
    text-transform: uppercase;
    padding: 8px 0;
    display: flex;
    font-size: 10px;
    justify-content: center;
    border-top: 1px solid var(--System-Surface5);
    cursor: pointer;
    color: var(--System-Secondary);
}

.history-item .time {
    color: var(--System-OnSurfaceVariant);
}

.history-item .lesser {
    font-size: 10px;
    font-weight: 400;
    color: var(--System-Outline);
}

.history-item .symbol-row {
    color: var(--System-OnSurface)
}

.history-item .symbol-row img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.history-item .symbol {
    zoom: 0.315;
    margin-right: 20px;
}

.item.type .green {
    padding: 5px;
    border-radius: var(--Corner-Extra-small);
    color: var(--Custom-GreenFixed) !important;
    background: var(--Statelayers-greenfixed-opacity12);
}

.item.type .red {
    padding: 5px;
    border-radius: var(--Corner-Extra-small);
    color: var(--Custom-RedFixed) !important;
    background: var(--Statelayers-redfixed-opacity12);
}

@-moz-document url-prefix() {
    .history-item .symbol {
        -moz-transform: scale(0.25);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: -24px;
        top: -24px;
    }

    .history-item .symbol-row {
        position: relative;
        padding-left: 20px;
    }
}

.history-item > .row {
    flex-wrap: nowrap;
}

.history-item .col {
    margin-right: 15px;
}

.history-item > .col:last-child {
    margin-right: 0;
    align-items: flex-end;
}

.history-item .profit {
    text-align: right;
}

.scroll {
    overflow-x: scroll;
    overflow-y: scroll;
}

.symbol {
    cursor: pointer;
}

.no-orders {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px
}

.no-orders i {
    font-size: 60px;
}

.no-orders span {
    text-align: center;
}

.item span:first-child {
    color: var(--System-Outline);
}

.symbol-container span {
    color: var(--System-OnSurface) !important;
}
</style>
