<template>
    <button class="monogram" :class="monogramStyles">
        <img v-if="user.avatar && !loadError" 
            :src="user.avatar ? apiBase + '/avatar/' + user.avatar : ''" 
            @error="loadError = true"
        />
        <span v-else>
            {{ user.firstname && user.firstname[0].toUpperCase() }}
        </span>
    </button>
</template>

<script>
import {mapGetters} from "vuex"
import { REST_ENDPOINT } from "@/common/config"

export default {
    name: "BaseMonogram",
    props: {
        monogramStyles: {
            type: String,
            default: ''
        }
    },
    data() {
      return {
        apiBase: REST_ENDPOINT,
        loadError: false
      }  
    },
    computed: {
        ...mapGetters(['user']),
    }
}
</script>

<style scoped>
.monogram {
    max-width: 40px;
    max-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--System-OnSecondaryContainer);
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.monogram:after{
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border: 3px solid rgba(58, 123, 204, 0.30);
    border-radius: 50%;
}

.monogram:has(:not(img)){
    padding: 5px;
    border: 3px solid #3d5977;
    background: var(--System-SecondaryContainer);
}

.monogram:has(:not(img)):after,
.monogram.monogram-square:after{
   display: none;
}

.monogram.monogram-square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 2px;
    font-size: 32px;
    border-color: transparent;
}

button.monogram:active {
    outline: 2px solid rgb(47, 51, 60) !important;
}

.monogram img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    object-position: center;
}
</style>
