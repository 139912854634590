<template>
    <div class="page setting">
        <BasePageTitle :title="'Settings'" />
        <BasePanel>
            <template #content>
                <UpdatePassword/>
            </template>
        </BasePanel>
        <BasePanel>
            <template #content>
                <TwoFactorProtection/>
            </template>
        </BasePanel>
        <BasePanel class="lang-panel " v-ripple="{color: 'var(--v-ripple-color2)'}">
            <template #content>
                <PlatformLanguage/>
            </template>
        </BasePanel>
        <BasePanel class="currency-panel" v-ripple="{color: 'var(--v-ripple-color2)'}">
            <template #content>
                <DashboardCurrency/>
            </template>
        </BasePanel>
        <BasePanel>
            <template #content>
                <DarkMode/>
            </template>
        </BasePanel>
    </div>
</template>

<script>
import BasePageTitle from "@/new-design/shared/ui/titles/BasePageTitle.vue"
import UpdatePassword from "@/new-design/features/Settings/UpdatePassword/ui/UpdatePassword.vue"
import BasePanel from "@/new-design/shared/ui/panels/BasePanel.vue"
import TwoFactorProtection from "@/new-design/features/Settings/TwoFactorProtection/ui/TwoFactorProtection.vue"
import DarkMode from "@/new-design/features/Settings/DarkMode/ui/DarkMode.vue"
import PlatformLanguage from "@/new-design/features/Settings/PlatformLanguage/ui/PlatformLanguage.vue";
import DashboardCurrency from "@/new-design/features/Settings/DashboardCurrency/ui/DashboardCurrency.vue";

export default {
    name: "SettingPage",
    components: {
        DashboardCurrency,
        PlatformLanguage, DarkMode, TwoFactorProtection, BasePanel, UpdatePassword, BasePageTitle}
}
</script>

<style scoped>
.panel {
    margin-bottom: 16px;
}

.lang-panel,
.currency-panel {
    cursor: pointer;
}


.lang-panel:hover,
.currency-panel:hover {
    background: var(--Statelayers-onsurface-opacity08);
}

.lang-panel:active,
.currency-panel:active {
    background: var(--Statelayers-onsurface-opacity16);
}
</style>
