<template>
    <div class="order-menu"
         ref="order-menu"
         :class="{short: ordersPane, hidedOnSmallScreen: !isMobile }"
         v-if="(modals.orderMenuGridWideModal && isMobile) || !isMobile">
        <div v-if="modals.orderMenuGridWideModal" class="order-menu-modal" >
            <div class="order-menu-modal-title">
                <div class="title-currency">
                    <div :class='`symbol symbol-${symbol.symbol}`'></div>
                    <span class="title__large">{{ symbol.symbol }}</span>
                </div>
                <i class="material-icons" @click="closeModal">close</i>
            </div>
            <BaseModalTabs 
                :tabs="['Buy', 'Sell']" 
                :active="activeModalTab"
                @updateActiveTab="updateModalTab" 
            />
        </div>

        <!-- old functionality -->
        <!-- <Calculator
                :visible="calculatorIsVisible"
                :input="calculatorInputValue"
                @close="calculatorIsVisible = false"
                @apply="applyCalcValue" /> -->

        <div class="grid-item">
            <!-- div with input and buttons for lots -->
            <div class="value-container"
                v-show="volumeType==='lots'">
                    <BaseNumberInput
                        :title="tokens.orderMenu.volume"
                        type="number" placeholder="0.0"
                        v-model="volumeInLots"
                        :validateFunction="updateInput"
                        @input="updateLotsInput" />
                    <BaseButton
                        class="minus"
                        :buttonStyle="'outlined-primary'"
                        @click.native="decreaseVolumeInLots">
                            <template #icon>
                                <i class="material-symbols-outlined">remove</i>
                            </template>
                    </BaseButton>
                    <BaseButton
                        class="plus"
                        :buttonStyle="'outlined-primary'"
                        @click.native="increaseVolumeInLots">
                            <template #icon>
                                <i class="material-symbols-outlined">add</i>
                            </template>
                    </BaseButton>
            </div>

            <!-- div with input and buttons for units -->
            <div class="value-container"
                v-show="volumeType==='units'">
                    <BaseNumberInput
                        :title="tokens.orderMenu.volume"
                        type="number"
                        placeholder="0.0"
                        v-model="volumeInUnits"
                        :validateFunction="updateInput"
                        @input="updateUnitsInput"/>
                    <BaseButton
                        class="minus"
                        :buttonStyle="'outlined-primary'"
                        @click.native="decreaseVolumeInUnits">
                            <template #icon>
                                <i class="material-symbols-outlined">remove</i>
                            </template>
                    </BaseButton>
                    <BaseButton
                        class="plus"
                        :buttonStyle="'outlined-primary'"
                        @click.native="increaseVolumeInUnits">
                            <template #icon>
                                <i class="material-symbols-outlined">add</i>
                            </template>
                    </BaseButton>
            </div>

            <!-- div with input and buttons for currency -->
            <div class="value-container"
                v-show="volumeType==='currency'">
                    <BaseNumberInput
                        :title="tokens.orderMenu.volume"
                        type="number"
                        placeholder="0.0"
                        v-model="volumeInCurrency"
                        :validateFunction="updateInput"
                        @input="updateCurrencyInput" />
                    <BaseButton
                        class="minus"
                        :buttonStyle="'outlined-primary'"
                        @click.native="decreaseVolumeInCurrency">
                            <template #icon>
                                <i class="material-symbols-outlined">remove</i>
                            </template>
                    </BaseButton>
                    <BaseButton
                        class="plus"
                        :buttonStyle="'outlined-primary'"
                        @click.native="increaseVolumeInCurrency">
                            <template #icon>
                                <i class="material-symbols-outlined">add</i>
                            </template>
                    </BaseButton>
            </div>

            <!-- lots / units / currency switch -->
            <div class="btns">
                <button
                    v-for="(volumesType, index) in volumesTypes"
                    :key="index" class="btn body__medium"
                    :class="{active: volumeType===volumesType}"
                    @click="volumeType=volumesType">
                    {{ tokens.orderMenu[volumesType] }}
                </button>
            </div>
        </div>

        <div class="grid-item order-vals">
            <OrderPanelVals
                :chart="chart"
                :contractSize="contractSize"
                :orderVals="orderVals"
                :pendingPrice="pendingPrice" />
        </div>

        <div class="grid-item profit-calculator">
            <BaseButton
                :buttonText="tokens.profitCalculatorModal.profitCalculator"
                :buttonStyle="'text-primary'"
                @click.native="showProfitCalculator" />
        </div>

        <!-- need to be used -->
        <!-- <div class="grid-item tpsl" @click="showAutoCloseModal" ref="autoclose-holder">
            <span>{{ tokens.orderMenu.takeProfitStopLoss }}</span>
            <span class="expiration">
                <span :class="{hide: tp || tp != 0 || sl || sl != 0}">{{ tokens.orderMenu.notSet }}</span>
                <span :class="{hide: tp == 0}">{{ tpType === '$' ? currencySign(activeAccount.currency) : '' }}{{ tp
                    }}{{ tpType === '%' ? '%' : '' }}</span>
                <span>{{ (tp > 0 && sl > 0) ? ' & ' : '' }}</span>
                <span :class="{hide: sl == 0}">{{ slType === '$' ? currencySign(activeAccount.currency) : '' }}{{ sl
                    }}{{ slType === '%' ? '%' : '' }}</span>
            </span>
        </div> -->

        <div class="grid-item">
            <div class="tpsl">
                <div class="tpsl-title">
                    <BaseCheckbox
                        @click.native="toggleBlock('tpslBlockState')"
                    />
                    <span class="body__large">{{ tokens.orderMenu.takeProfitStopLoss }}</span>
                </div>
                <span v-if="tpslBlockState" class="tpsl-inner">
                    <AutoCloseBlock :data="autoCloseData" />
                </span>
            </div>
            <div class="pending">
                <div class="pending-title">
                    <BaseCheckbox
                        @click.native="toggleBlock('pendingBlockState')"
                    />
                    <span class="body__large">{{ tokens.orderMenu.pending }}</span>
                </div>
                <span v-if="pendingBlockState" class="pending-inner">
                    <PendingBlock :data="pendingData" />
                </span>
            </div>
        </div>

        <div class="grid-item meter">
            <BaseChartFearGreedIndex :data="trend" />
        </div>
        <div class="grid-item buy-sell">
            <BaseTradingButton
                v-if="(isMobile && activeModalTab == 0) || !isMobile"
                :buttonText="sessionsState[currentSym] ? tokens.orderMenu.buy.toUpperCase() : 'Closed'"
                :buttonClass="'buy' + (!sessionsState[currentSym] ? ' close' : '') + (tradeDisabled ? ' disabled' : '')"
                @click.native="openOrder(0)">
                    <template #sum>
                        <span class="price">
                            <span>
                                {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                                symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym].quote &&
                                    symbols[currentSym]?.quote.ask ?
                                symbols[currentSym]?.quote.ask.toFixed(symbols[currentSym].precision).slice(0, -2) : 0 :
                                ''}}
                            </span>
                            <span>
                                {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                                symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote ?
                                symbols[currentSym]?.quote.ask.toFixed(symbols[currentSym].precision).slice(-2) : 0 : ''}}
                            </span>
                        </span>
                    </template>
            </BaseTradingButton>
            <BaseTradingButton
                v-if="(isMobile && activeModalTab == 1) || !isMobile"
                :buttonText="sessionsState[currentSym] ? tokens.orderMenu.sell.toUpperCase() : 'Closed'"
                :buttonClass="'sell' + (!sessionsState[currentSym] ? ' close' : '') + (tradeDisabled ? ' disabled' : '')"
                @click.native="openOrder(1)">
                    <template #sum>
                        <span class="price">
                            <span>
                                {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                                symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote &&
                                    symbols[currentSym]?.quote.ask ?
                                symbols[currentSym]?.quote.bid.toFixed(symbols[currentSym].precision).slice(0, -2) : 0 : ''
                                }}
                            </span>
                            <span>
                                {{ sessionsState[currentSym] && symbols[currentSym] && symbols[currentSym]?.quote &&
                                symbols[currentSym]?.quote.bid ? symbols[currentSym] && symbols[currentSym]?.quote ?
                                symbols[currentSym]?.quote.bid.toFixed(symbols[currentSym].precision).slice(-2) : 0 : ''
                                }}
                            </span>
                        </span>
                    </template>
            </BaseTradingButton>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_AMOUNTS, MODAL_AUTOCLOSE, MODAL_PENDING, NOTIFICATION_ERROR, ORDERS_PANE, MODAL_ORDER_MENU_GRID_WIDE} from '@/store/mutations.type'
import {isMobile} from "@/common/helpers"
import {CUSTOM_TPSL, VOLUME_TYPES} from "@/common/config"
import {CFD_ORDER_OPEN_REQUEST} from '@/store/actions.type'
import BaseChartFearGreedIndex from '@/new-design/not-sorted/new/BaseChartFearGreedIndex'
import BaseNumberInput from "@/new-design/shared/ui/Inputs/BaseNumberInput";
import BaseButton from '@/new-design/shared/ui/buttons/BaseButton'
import BaseTradingButton from '@/new-design/shared/ui/buttons/BaseTradingButton'
import BaseModalTabs from '@/new-design/shared/ui/tabs/BaseModalTabs.vue'
import AutoCloseBlock from '@/new-design/not-sorted/new/OrderMenuGridWide/AutoCloseBlock.vue'
import PendingBlock from '@/new-design/not-sorted/new/OrderMenuGridWide/PendingBlock.vue'
import OrderPanelVals from '@/new-design/not-sorted/new/OrderMenuGridWide/OrderPanelVals'
import BaseCheckbox from "@/new-design/shared/ui/Inputs/BaseCheckbox.vue";
// import Calculator from '@/components/Calculator'

export default {
    name: "OrderMenu",
    components: {
        BaseCheckbox,
        BaseChartFearGreedIndex,
        OrderPanelVals,
        BaseNumberInput,
        BaseButton,
        AutoCloseBlock,
        PendingBlock,
        BaseTradingButton,
        BaseModalTabs
    },
    props: ['chart', 'gridId', 'visible'],
    data() {
        return {
            tpslBlockState: false,
            pendingBlockState: false,
            leverage: 100,
            tp: '',
            tpType: '=',
            sl: '',
            slType: '=',
            pendingPrice: '',
            volumesTypes: this.user?.volume_units.split(',') || VOLUME_TYPES,
            volumeType: this.user?.volume_units.split(',')[0] || 'lots',
            customTpSl: CUSTOM_TPSL,

            volumeInUnits: 1,
            volumeInLots: 0,
            volumeInCurrency: 0,

            lotStepLength: 2,
            lotStepStr: '',
            lotStepChecks: [],

            activeModalTab: 0,
            isMobile: isMobile(),

            // calculatorInput: '',
            // calculatorIsVisible: false,
        }
    },
    mounted() {
        this.$store.commit(MODAL_AMOUNTS, {
            contractSize: this.contractSize
        })
    },
    methods: {
        showProfitCalculator() {
            this.$store.commit(MODAL, {
                profitCalculator: true,
                account: false,
            })
        },
        toggleBlock(state) {
            this[state] = !this[state]
        },
        updateInput(e) {
            return (e.endsWith('.') || e.includes('.')) ? e.replace(/,/g, "") : Number(e.replace(/,/g, ""));
        },
        decreaseVolumeInLots() {
            this.volumeInLots = Number(((this.volumeInLots - this.symbols[this.currentSym].lot_step) < 0 ? this.volumeInLots : parseFloat(this.volumeInLots) - this.symbols[this.currentSym].lot_step).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((this.volumeInLots * this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        increaseVolumeInLots() {
            this.volumeInLots = Number((parseFloat(this.volumeInLots) + this.symbols[this.currentSym].lot_step).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((this.volumeInLots * this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        decreaseVolumeInUnits() {
            this.volumeInUnits = this.volumeInUnits <= 1 ? this.volumeInUnits : this.volumeInUnits - 1
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        increaseVolumeInUnits() {
            this.volumeInUnits = parseFloat(this.volumeInUnits) + 1
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },
        decreaseVolumeInCurrency() {
            this.volumeInCurrency = this.volumeInCurrency <= 1 ? this.volumeInCurrency : this.volumeInCurrency - 1
            this.volumeInUnits = Number((this.volumeInCurrency * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
        },
        increaseVolumeInCurrency() {
            this.volumeInCurrency = parseFloat(this.volumeInCurrency) + 1
            this.volumeInUnits = Number((this.volumeInCurrency * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
        },

        updateLotsInput(e) {
            let val = e
            if(val.length === 0 || !this.checkLotStepZeros){
                this.volumeInLots = '0'
                return
            }

            if(val.slice(-1) === '.' || this.checkLotStepZeros.indexOf(val) !== -1) {
                return
            }
            if(val.slice(-1) === ',') {
                this.volumeInLots = val.split(',')[0]+'.'
                return
            }

            this.volumeInLots = Number(parseFloat(val).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((val * this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },

        updateUnitsInput(e) {
            let val = e

            if(val.length === 0 || !this.checkLotStepZeros){
                this.volumeInUnits = '0'
                return
            }
            if(val.slice(-1) === '.' || this.checkLotStepZeros.indexOf(val) !== -1) {
                return
            }
            if(val.slice(-1) === ',') {
                this.volumeInUnits = val.split(',')[0]+'.'
                return
            }

            this.volumeInUnits = Number(parseFloat(val).toFixed(this.lotStepLength))
            this.volumeInLots = Number((val / this.contractSize).toFixed(this.lotStepLength))
            this.volumeInCurrency = Number((val * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
        },

        updateCurrencyInput(e) {
            let val = e

            if(val.length === 0 || !this.checkLotStepZeros){
                this.volumeInCurrency = '0'
                return
            }
            if(val.slice(-1) === '.' || this.checkLotStepZeros.indexOf(val) !== -1) {
                return
            }
            if(val.slice(-1) === ',') {
                this.volumeInCurrency = val.split(',')[0]+'.'
                return
            }

            this.volumeInCurrency = Number(parseFloat(val).toFixed(this.lotStepLength))
            this.volumeInUnits = Number((val * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
            this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
        },

        openOrder(type) {
            if (!this.symbols[this.currentSym]) {
                return
            }

            if(!this.sessionsState[this.currentSym] || !this.symbols[this.currentSym].enable){
                this.$store.commit(NOTIFICATION_ERROR, 'MARKET_CLOSED')
                return
            }

            if (!this.activeAccount.enable) {
                this.$store.commit(NOTIFICATION_ERROR, 'TRADING_DISABLED')
                return
            }

            let price = this.pendingPrice || this.symbols[this.currentSym].quote[type ? 'bid' : 'ask'],
                sl = 0,
                tp = 0

            if (this.tp) {
                switch (this.tpType) {
                    case '%':
                        tp = Number(price) + (type ? -1 : 1) * price * this.tp / (100 * this.leverage)
                        break
                    case '$':
                        tp = Number(price) + (type ? -1 : 1) * price * this.tp / (this.leverage * this.volume)
                        break
                    case '=':
                        tp = this.tp
                        break
                }
            }

            if (this.sl) {
                switch (this.slType) {
                    case '%':
                        sl = Number(price) + (type ? 1 : -1) * price * this.sl / (100 * this.leverage)
                        break
                    case '$':
                        sl = Number(price) + (type ? 1 : -1) * price * this.sl / (this.leverage * this.volume)
                        break
                    case '=':
                        sl = this.sl
                        break
                }
            }

            if (this.pendingPrice) {
                let pendingType = 0

                if (type) {
                    if (this.pendingPrice < this.symbols[this.currentSym].quote.bid) {
                        pendingType = 5
                    } else {
                        pendingType = 3
                    }
                } else {
                    if (this.pendingPrice < this.symbols[this.currentSym].quote.ask) {
                        pendingType = 2
                    } else {
                        pendingType = 4
                    }
                }

                type = pendingType
            }

            if(this.customTpSl) {
                if (type == 0) {
                    if (sl && this.pendingPrice - sl < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid SL')
                        return
                    }
                    if (tp && tp - this.pendingPrice < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid TP')
                        return
                    }
                }

                if (type == 1) {
                    if (sl && sl - this.pendingPrice < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid SL')
                        return
                    }
                    if (tp && this.pendingPrice - tp < 20 * Math.pow(10, this.symbols[this.currentSym].precision)) {
                        this.$store.commit(NOTIFICATION_ERROR, 'Invalid TP')
                        return
                    }
                }
            }

            let order = {
                type,
                symbol: this.currentSym,
                volume: this.orderVals.position,
                account_id: this.activeAccount.id,
                sl,
                tp,
                price_open: this.pendingPrice || undefined
            }
            // if (this.tp && this.tp >= this.symbols[this.currentSym].bid) {
            //     order.tp = this.calcPrice(this.currentSym,order,this.activeAccount, tp)
            // }
            //
            // if (this.sl && type != 1) {
            //     order.sl = this.calcPrice(this.currentSym,order,this.activeAccount, sl, 'sl')
            // }

            this.$store.dispatch(CFD_ORDER_OPEN_REQUEST, order).then(() => {
                this.tp = ''
                this.sl = ''

                if(this.isMobile){
                    this.closeModal()
                    this.openOrdersPane()
                }
            })
        },
        updateModalTab(tabIndex, fromWatch = false){
            if (fromWatch) {
                this.activeModalTab = tabIndex;
            } else {
                this.$store.commit(MODAL_ORDER_MENU_GRID_WIDE, { tab: tabIndex });
            }
        },
        closeModal(){
            this.$store.commit(MODAL, {
                orderMenuGridWideModal: false,
            })
        },
        openOrdersPane() {
            this.$store.commit(ORDERS_PANE, true)
        },

        /* calcPrice(symbolConf, newOrder, account, targetProfit, mode = 'tp'){
            let point = newOrder.volume

            let baseRate = 1, baseCur = symbolConf.base_currency,
                accRate = 1, accCur = account.currency

            if (this.symbols['USD'+baseCur] !== undefined) {
                baseRate = this.symbols['USD'+baseCur].quote.bid
            } else if (this.symbols[baseCur+'USD'] !== undefined) {
                baseRate = 1 / this.symbols[baseCur+'USD'].quote.bid
            }

            if (this.symbols['USD'+accCur] !== undefined) {
                accRate = this.symbols['USD'+accCur].quote.bid
            } else if (this.symbols[accCur+'USD'] !== undefined) {
                accRate = 1 / this.symbols[accCur+'USD'].quote.bid
            }

            point *= accRate / baseRate

            let dif = targetProfit / point,
                mult = mode === 'tp' ? 1 : -1

            switch (newOrder.type) {
                case 0:
                    return parseFloat(this.symbols[newOrder.symbol].quote.bid + mult * dif)
                case 1:
                    return parseFloat(this.symbols[newOrder.symbol].quote.ask + mult * dif)
                case 2:
                case 4:
                    return newOrder.price_open + mult * dif
                case 3:
                case 5:
                    return newOrder.price_open - mult * dif
                default:
                    throw new Error('Invalid order type')
            }
        }, */
        /* focusLots() {
            this.calculatorIsVisible = true
            this.calculatorInput = 'lots'
        },
        focusUnits() {
            this.calculatorIsVisible = true
            this.calculatorInput = 'units'
        },
        focusCurrency() {
            this.calculatorIsVisible = true
            this.calculatorInput = 'currency'
        }, */
        /* applyCalcValue(val) {
            if (!this.lotStepLength){
                return
            }

            if (this.calculatorInput === 'lots') {
                this.volumeInLots = Number(parseFloat(val).toFixed(this.lotStepLength))
                this.volumeInUnits = Number((val * this.contractSize).toFixed(this.lotStepLength))
                this.volumeInCurrency = Number((this.volumeInUnits * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
            } else if (this.calculatorInput === 'units') {
                this.volumeInUnits = Number(parseFloat(val).toFixed(this.lotStepLength))
                this.volumeInLots = Number((val / this.contractSize).toFixed(this.lotStepLength))
                this.volumeInCurrency = Number((val * this.symbols[this.currentSym].quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
            } else {
                this.volumeInCurrency = Number(parseFloat(val).toFixed(this.lotStepLength))
                this.volumeInUnits = Number((val * this.computedConversions.acc / (this.computedConversions.base * this.symbols[this.currentSym].quote.bid)).toFixed(this.lotStepLength))
                this.volumeInLots = Number((this.volumeInUnits / this.contractSize).toFixed(this.lotStepLength))
            }

        }, */
    },
    computed: {
        ...mapGetters(['symbols', 'orderMenuGridWideModal', 'sessionsState','activeAccount', 'tokens', 'modalAutoClose', 'modalPending', 'tradeDisabled', 'ordersPane', 'orders', 'user', 'conversions','modals', 'chartActive', 'charts',]),

        autoCloseData(){
            return {
                symbolActive: this.symbols[this.currentSym],
                tp: this.tp,
                sl: this.sl,
            }
        },
        pendingData(){
            return {
                symbolActive: this.symbols[this.currentSym],
                price: this.pendingPrice,
            }
        },
        checkLotStepZeros(){
            if (this.lotStepStr == 0) {
                return
            }

            let stepArray = []
            let step = "0.0",
                curLot = this.lotStepStr.slice(0, -1)

            for (let i = 0; i < 10; i++) {
                stepArray.push(step)
                if(curLot !== '0' && step === curLot){
                    break
                }
                step += '0'
            }

            return stepArray
        },
        trend() {
            return (this.symbols && this.currentSym) ? this.symbols[this.currentSym].quote.trend : 50
        },
        curSymObj() {
            return this.symbols[this.currentSym] || {lot_step: 0}
        },
        orderVals() {
            let vals = {
                position: 0,
                margin: 0,
                freeMargin: 0,
                leverage: 1
            }

            if (!this.symbols[this.currentSym] || !this.activeAccount.id || !this.volumeInLots) {
                return vals
            }

            vals.position = parseFloat(this.volumeInLots) * this.contractSize
            vals.leverage = this.symbols[this.currentSym].leverage_default || this.activeAccount.leverage

            let swapAndCommission = 0
            let marginBySymbol = {}

            for(let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]
                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }
                marginBySymbol[o.symbol] += o.type == 0 ? Number(o.margin) : -Number(o.margin)

            }
            for(let n in marginBySymbol) {
                swapAndCommission += Math.abs(marginBySymbol[n])
            }

            let baseRate = 1, baseCur = this.symbols[this.currentSym].base_currency,
                accRate = 1, accCur = this.activeAccount.currency

            if (this.symbols["USD" + baseCur] !== undefined) {
                baseRate = this.symbols["USD" + baseCur].quote.bid
            } else if (this.symbols[baseCur + "USD"] !== undefined) {
                baseRate = 1 / this.symbols[baseCur + 'USD'].quote.bid
            }

            if (this.symbols["USD" + accCur] !== undefined) {
                accRate = this.symbols["USD" + accCur].quote.bid
            } else if (this.symbols[accCur + "USD"] !== undefined) {
                accRate = 1 / this.symbols[accCur + 'USD'].quote.bid
            }

            let marginRate = accRate / baseRate
            let margin = vals.position / vals.leverage

            vals.margin = parseFloat((margin * this.symbols[this.currentSym].quote.bid * marginRate).toFixed(this.activeAccount.precision))  || 0

            // pnl:
            let pnl = 0

            for(let i in this.orders) {
                let order = this.orders[i]
                if (order.type > 1 || !this.symbols[order.symbol]) {
                    continue
                }
                pnl += order.point * (order.type === 0 ? this.symbols[order.symbol].quote.bid - order.price_open : order.price_open - this.symbols[order.symbol].quote.ask) * Math.pow(10, this.symbols[order.symbol].precision) + order.swap + order.commission
            }

            // free margin:
            let equity = this.activeAccount.balance + this.activeAccount.credit + pnl
            vals.freeMargin = equity - swapAndCommission

            return vals
        },
        currentSym() {
            return this.chart.symbol || ''
        },
        contractSize() {
            return this.symbols[this.currentSym] !== undefined ? (this.symbols[this.currentSym].lot || 1) : 1
        },
        computedConversions() {
            let conv = {
                base: 1,
                acc: 1
            }

            if (this.symbols[this.currentSym] === undefined) {
                return conv
            }

            let accCur = this.activeAccount.currency

            // let baseCur = this.symbols[this.currentSym].base_currency,

            // if (baseCur !== 'USD') {
            //     if (this.symbols[baseCur+'USD'] !== undefined) {
            //         conv.base = this.symbols[baseCur + 'USD'].quote.bid
            //     } else if (this.symbols['USD'+baseCur] !== undefined) {
            //         conv.base = 1 / this.symbols['USD' + baseCur].quote.bid
            //     } else {
            //         conv.base = this.conversions[baseCur]
            //     }
            // }

            if (accCur !== 'USD') {
                if (this.symbols[accCur+'USD'] !== undefined) {
                    conv.acc = this.symbols[accCur + 'USD'].quote.bid
                } else if (this.symbols['USD'+accCur] !== undefined) {
                    conv.acc = 1 / this.symbols['USD'+accCur].quote.bid
                } else {
                    conv.acc = this.conversions[accCur]
                }
            }

            return conv
        },
        symbol() {
            let symbol = {
                alias: ''
            }

            if (!this.chartActive || !this.charts[this.chartActive]) {
                return symbol
            }
            return this.symbols[this.charts[this.chartActive].symbol] || symbol
        },
        /* calculatorInputValue() {
            switch (this.calculatorInput) {
                case 'lots':
                    return this.volumeInLots
                case 'currency':
                    return this.volumeInCurrency
                case 'units':
                default:
                    return this.volumeInUnits
            }
        } */
    },
    watch: {
        activeAccount(val) {
            if (!val) {
                return
            }
            this.leverage = val.leverage
        },
        sl(val) {
            this.sl = String(val).split(',').join('.')
        },
        tp(val) {
            this.tp = String(val).split(',').join('.')
        },
        modalAutoClose: {
            deep: true,
            handler(val) {
                if(!val.orderActive) {
                    if(val.event){
                      this.tp = val.tp
                      this.sl = val.sl
                      this.$store.commit(MODAL_AUTOCLOSE, {event: 0})
                    }
                    this.tpType = val.tpType
                    this.slType = val.slType
                }
            }
        },
        modalPending: {
            deep: true,
            handler(val) {
                if(val.event){
                    this.pendingPrice = val.price
                    this.$store.commit(MODAL_PENDING, {event: 0})
                }
            }
        },
        curSymObj: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal === oldVal) {
                    return
                }

                if(!newVal.lot_step){
                    return
                }

                this.$set(this, 'lotStepLength', newVal.lot_step.toString().length - 2 > 0 ? newVal.lot_step.toString().length - 2 : 2)
                this.$set(this, 'lotStepStr', newVal.lot_step.toString())
                this.$set(this, 'lotStepChecks', this.checkLotStepZeros)

                this.volumeInLots = newVal.lot_step || 0.01
                this.volumeInUnits = Number((this.volumeInLots * newVal.lot).toFixed(3))

                if (newVal.quote !== undefined) {
                    this.volumeInCurrency = Number((this.volumeInUnits * newVal.quote.bid * this.computedConversions.base / this.computedConversions.acc).toFixed(this.activeAccount.precision))
                }
            }
        },
        user: {
            deep: true,
            handler(newVal) {
                if (newVal.volume_units === '') {
                    this.$set(this, 'volumesTypes', VOLUME_TYPES)
                } else {
                    this.$set(this, 'volumesTypes', this.user.volume_units.split(','))
                }
                    // this.$set(this, 'volumeType', this.volumesTypes[0])
                    this.$set(this, 'volumeType', 'lots')
            }
        },
        orderMenuGridWideModal: {
            deep: true,
            handler(val) {
                if(val && val.tab != this.activeModalTab){
                    this.updateModalTab(val.tab, true)
                }
            }
        },
    }
}
</script>

<style scoped>
.order-menu {
    display: grid;
    grid-template-columns: 320px;
    grid-template-rows: repeat(5, auto);
    align-content: start;
    /* gap: var(--gutter); */
    /* gap: 16px; */
    /* padding: 5px 10px; */
    overflow: hidden;
    overflow-y: scroll;
    --gutter: 7px;

    max-height: calc(100vh - 135px - 45px - 30px);
    position: relative;
}

.order-menu.short {
    max-height: calc(100vh - (0.1796 * 100vh + 110px) - 45px - 30px);
}

.banner-is-active .order-menu.hidedOnSmallScreen{
    max-height: calc(100vh - 135px - 45px - 30px - 44px);
}
.banner-is-active .order-menu.short.hidedOnSmallScreen{
    max-height: calc(100vh - (0.1796 * 100vh + 110px) - 45px - 30px - 37px);
}

.grid-item {
    margin-bottom: 16px;
}

.grid-item.order-vals {
    padding: 0 10px;
}
.grid-item.profit-calculator {
    margin-top: -6px;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}

.grid-item:last-child {
    margin-bottom: 0;
}

.value-container {
    cursor: pointer;
    position: relative;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    padding: 16px 10px 5px;
    margin-bottom: 12px;
}

.btns {
    margin-top: 1px;
    display: flex;
    align-items: stretch;
    gap: 8px;
    padding: 0 12px;
    text-transform: capitalize;
}

.btn {
    height: 28px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--System-Surface1);
    color: var(--System-OnSurfaceVariant);
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s ease;
    text-transform: capitalize;
}

.btn:hover {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.btn:focus-visible {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.btn.active {
    color: var(--System-Primary);
    background-color: var(--System-Surface5);
}

.btn.active:hover {
    background-color: var(--Statelayers-primary-opacity08);
}

.btn.active:focus-visible {
    background-color: var(--Statelayers-primary-opacity12);
}

.btn:not(:last-child) {
    margin-right: 2px;
}

.tpsl {
    border-top: 1px solid var(--System-Surface5);
    border-bottom: 1px solid var(--System-Surface5);
}

.pending {
    border-bottom: 1px solid var(--System-Surface5);
}

.tpsl-title,
.pending-title {
    color: var(--System-OnSurfaceVariant);
    padding: 4px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    background: var(--Statelayers-surface5-opacity08);
}

.tpsl-checkbox,
.pending-checkbox {
    color: var(--System-Primary);
    font-size: 20px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    display: flex;
}

.tpsl-checkbox:hover:after,
.pending-checkbox:hover:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: var(--Statelayers-primary-opacity08);
    border-radius: 50%;
}

.buy-sell {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--gutter);
    border-top: 1px solid var(--System-Surface5);
    background: var(--System-Surface);
    padding: 16px 10px;
    position: sticky;
    bottom: -0.5px;
    left: 0;
    place-content: center;
    height: 80px;
}

.meter {
    margin-top: 10px;
    place-self:  center center;
}

.order-menu-modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px;
}

.title-currency{
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--System-OnSurface)
}

.title-currency div {
     zoom: .4;
}

@media (max-width: 768px) {
    .order-menu {
        grid-template-columns: 1fr;
        background: var(--System-Surface);
        position: fixed;
        inset: 0;
        max-height: 100%;
        height: 100%;
        z-index: 888;
        padding-top: 124px;
        padding-bottom: 70px;
    }

    .order-menu.short {
        max-height: 100%;
    }
    .order-menu.hidedOnSmallScreen {
        display: none
    }

    .order-menu-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: var(--System-Surface);
        z-index: 1;

    }

    .buy-sell {
        position: fixed;
        width: 100%;
        grid-template-columns: 1fr;
    }
}

/* Phones */

/*@media (max-width: 500px) {*/
/* @media screen and (orientation: portrait) {
    .order-menu {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        gap: var(--gutter);
        --gutter: 5px;
    }

    .volume-with-buttons { grid-column: 1/2; grid-row: 1/2; }
    .order-vals          { grid-column: 2/3; grid-row: 1/3; }
    .tpsl                { grid-column: 1/2; grid-row: 2/3; }
    .pending             { grid-column: 1/2; grid-row: 3/4; }
    .buy-sell            { grid-column: 2/3; grid-row: 3/4; }





    .buy-sell {
        gap: var(--gutter);
    }

    .button {
        padding: 10px 0;
    }

    .button .price {
        display: none;
    }
}
 */

/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
