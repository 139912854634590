import { render, staticRenderFns } from "./SavingsAccountsTab.vue?vue&type=template&id=29d2c014&scoped=true"
import script from "./SavingsAccountsTab.vue?vue&type=script&lang=js"
export * from "./SavingsAccountsTab.vue?vue&type=script&lang=js"
import style0 from "./SavingsAccountsTab.vue?vue&type=style&index=0&id=29d2c014&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29d2c014",
  null
  
)

export default component.exports