<script>
import toggler from "@/new-design/shared/mixins/toggler"

export default {
    name: "BaseToggleSmallButton",
    props: {
        buttonStyle: {
            type: String,
        }
    },
    mixins: [toggler],
}
</script>

<template>
    <button
        class="toggle-small-button"
        :class="[buttonStyle, {active: state}]"
        @click="toggler()"
    >
        <span class="state-layer">
            <slot v-if="!state" name="init-icon"/>
            <slot v-else name="active-icon"/>
            <slot name="icon"/>
        </span>
    </button>
</template>

<style scoped>
.toggle-small-button {
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.toggle-small-button .state-layer {
    padding: 2px;
    display: inline-flex;
    border-radius: var(--Corner-Extra-small);
}

.toggle-small-button path {
    fill: var(--System-SurfaceVariant);
}

.toggle-small-button:hover .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

.toggle-small-button:focus .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity12);
}

.toggle-small-button:active .state-layer {
    background: var(--Statelayers-onsurfacevariant-opacity16);
}

.toggle-small-button.active:disabled
.toggle-small-button:disabled {
    opacity: 0.38;
}

.toggle-small-button.active path {
   fill: var(--System-Primary);
}

.toggle-small-button.active:hover .state-layer {
    background: var(--Statelayers-primary-opacity08);
}

.toggle-small-button.active:focus .state-layer {
    background: var(--Statelayers-primary-opacity12);
}

.toggle-small-button.active:active .state-layer {
    background: var(--Statelayers-primary-opacity16);
}
</style>
