<template>
    <header class="header">
        <div class="row left-row">
            <BaseToggleIconButton class="burger" :button-style="'secondary'" @update="showMenu">
                <template #init-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M3 18.5V16.5H21V18.5H3ZM3 13.5V11.5H21V13.5H3ZM3 8.5V6.5H21V8.5H3Z" fill="#BFC6DC"/>
                    </svg>
                </template>
                <template #active-icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M3 18.5V16.5H21V18.5H3ZM3 13.5V11.5H21V13.5H3ZM3 8.5V6.5H21V8.5H3Z" fill="#AFC6FF"/>
                    </svg>
                </template>
            </BaseToggleIconButton>
            <BaseLogo v-tooltip="website" class="header-logo" :src="logoComputed" :height="logo.height">
                <template v-if="!logoComputed" #logo-placeholder>
                    <span class="brand">{{ tokens.header.cfd }}</span>
                    {{ tokens.header.platform }}
                </template>
            </BaseLogo>
        </div>
        <div class="row right-row">
            <BaseAutotraderButton
                v-tooltip="'Auto Trader'"
                @click.native="showAutoTraderModal"
                class="autotrader"
            />
            <div class="account-box">
                <BaseAccountType :accountType="accountName"/>
                <BaseActiveAccountButton
                    :balance="activeAccount.balance.toFixed(2).toString()"
                    :symbol="activeAccount.currency"
                    @click.native="openBalanceModal"/>
                <BaseDepositButton
                    @click.native="openDepositsPage"
                />
            </div>
            <div class="profile">
                <BaseToggleIconButton class="settings" :button-style="'secondary'" @update="showMenuSettings">
                    <template #init-icon>
                        <span class="material-symbols-outlined">settings</span>
                    </template>
                    <template #active-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9.25001 22L8.85001 18.8C8.63335 18.7167 8.42918 18.6167 8.23751 18.5C8.04585 18.3833 7.85835 18.2583 7.67501 18.125L4.70001 19.375L1.95001 14.625L4.52501 12.675C4.50835 12.5583 4.50001 12.4458 4.50001 12.3375V11.6625C4.50001 11.5542 4.50835 11.4417 4.52501 11.325L1.95001 9.375L4.70001 4.625L7.67501 5.875C7.85835 5.74167 8.05001 5.61667 8.25001 5.5C8.45001 5.38333 8.65001 5.28333 8.85001 5.2L9.25001 2H14.75L15.15 5.2C15.3667 5.28333 15.5708 5.38333 15.7625 5.5C15.9542 5.61667 16.1417 5.74167 16.325 5.875L19.3 4.625L22.05 9.375L19.475 11.325C19.4917 11.4417 19.5 11.5542 19.5 11.6625V12.3375C19.5 12.4458 19.4833 12.5583 19.45 12.675L22.025 14.625L19.275 19.375L16.325 18.125C16.1417 18.2583 15.95 18.3833 15.75 18.5C15.55 18.6167 15.35 18.7167 15.15 18.8L14.75 22H9.25001ZM12.05 15.5C13.0167 15.5 13.8417 15.1583 14.525 14.475C15.2083 13.7917 15.55 12.9667 15.55 12C15.55 11.0333 15.2083 10.2083 14.525 9.525C13.8417 8.84167 13.0167 8.5 12.05 8.5C11.0667 8.5 10.2375 8.84167 9.56251 9.525C8.88751 10.2083 8.55001 11.0333 8.55001 12C8.55001 12.9667 8.88751 13.7917 9.56251 14.475C10.2375 15.1583 11.0667 15.5 12.05 15.5Z" fill="#E3E2E9"/>
                        </svg>
                    </template>
                </BaseToggleIconButton>
                <div
                    class="profile-settings-avatar"
                    @click="showCabinet('dashboard')"
                >
                    <BaseMonogram v-tooltip="'Your profile and settings'" />
                </div>
            </div>
        </div>
        <MenuSettingsModal/>
    </header>
</template>

<script>
import BaseAccountType from "@/new-design/shared/ui/account-type/BaseAccountType.vue"
import BaseLogo from "@/new-design/shared/ui/Icons/BaseLogo.vue"
import {mapGetters} from "vuex"
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type";
import {LOGO, REST_ENDPOINT, TRADINGVIEW_THEME, WEBSITE, WHITE_ASPIN_THEME} from "@/common/config"
import BaseAutotraderButton from "@/new-design/shared/ui/buttons/BaseAutotraderButton.vue"
import BaseDepositButton from "@/new-design/shared/ui/buttons/BaseDepositButton.vue"
import BaseMonogram from "@/new-design/shared/ui/Icons/BaseMonogram.vue"
import BaseActiveAccountButton from "@/new-design/shared/ui/buttons/BaseActiveAccountButton.vue"
import BaseToggleIconButton from "@/new-design/shared/ui/buttons/BaseToggleIconButton.vue"
import MenuSettingsModal from "@/new-design/features/Modals/MenuSettings/ui/MenuSettingsModal.vue";

export default {
    name: "TheHeader",
    components: {
        MenuSettingsModal,
        BaseToggleIconButton,
        BaseActiveAccountButton,
        BaseMonogram, BaseDepositButton, BaseAutotraderButton, BaseLogo, BaseAccountType
    },
    data() {
        return {
            tradingViewTheme: TRADINGVIEW_THEME,
            whiteAspinTheme: WHITE_ASPIN_THEME,
            logo: LOGO,
            website: WEBSITE,
            apiBase: REST_ENDPOINT,
        }
    },
    methods: {
        showMenuSettings(state) {
            this.$store.commit(MODAL, {
                menuSettings: state
            })
        },
        showMenu() {
            let state = this.modals.sideMenuBurger;
            this.$store.commit(MODAL, {
                sideMenuBurger: !state
            })
        },
        openBalanceModal() {
            this.$store.commit(MODAL, {
                balance: true
            })
        },
        showCabinet(page) {
            this.$store.commit(MODAL, {
                fullscreen: true,
            })
            this.$store.commit(MODAL_FULLSCREEN, {
                page
            })
        },
        showAutoTraderModal() {
            this.$store.commit(MODAL, {
                autoTrader: true
            })
        },
        openDepositsPage() {
            this.$store.commit(MODAL, {
                fullscreen: true
            })

            this.$store.commit(MODAL_FULLSCREEN, {
                page: 'deposit',
                fromPage: ''
            })
        }
    },
    computed: {
        ...mapGetters(['staticBaseDomain', 'lightTheme', 'tokens', 'accounts', 'activeAccount', 'modals', 'user']),
        logoComputed() {
            let url = this.logo.url

            if (this.staticBaseDomain !== 'co') {
                const parts = url.split('/')
                parts[2] = parts[2].replace('co', this.staticBaseDomain)
                url = parts.join('/')
            }

            if (this.whiteAspinTheme) {
                return this.lightTheme ? url.replace(/white/, 'black') : url
            } else {
                return this.tradingViewTheme === 'Light' ? url.replace(/white/, 'black') : url
            }
        },
        accountName() {
            return (this.activeAccount.alias && this.activeAccount.alias.toUpperCase()) ||
                (this.activeAccount.account_name && this.activeAccount.account_name != 'MAIN' ?
                    this.activeAccount.account_name : this.activeAccount.group === 'main' ?
                        'STANDARD' : this.activeAccount.group.toUpperCase())
        }
    }
}
</script>

<style scoped>
.row {
    display: flex;
    align-items: center;
}

.header {
    flex: 0 0 56px;
    display: flex;
    align-items: center;
    padding: 10px 8px;
    border-bottom: 1px solid var(--border);
    justify-content: space-between;
    background: var(--System-Surface);
    position: relative;
    max-height: 61px;
}

.left-row {
    overflow: hidden;
    flex: 1 1 auto;
    flex-wrap: nowrap;
}

.right-row {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
}

.profile,
.account-box {
    display: flex;
    align-items: center;
}

.account-box {
    gap: 16px;
    border-left: 1px solid var(--System-OutlineVariant);
    padding-left: 16px;
    margin: 0 24px;
}

.profile {
    gap: 28px;
}

.settings {
    cursor: pointer;
}

.brand {
    color: var(--brand) !important;
}

.burger {
    display: none;
}

@media (max-width: 1023px) {
    .burger {
        display: inline-flex;
        margin-right: 4px;
    }

    .account-type {
        display: none;
    }
}

@media (max-width: 479px) {
    .settings {
        display: none;
    }

    .account-box {
        padding: 0;
        border: none;
        margin: 0 16px;
    }
}
</style>
