<script>
export default {
    name: "BaseAutotraderButton"
}
</script>

<template>
    <button class="autotrader-button">
        <span class="state-layer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.5 13.5C13.9722 13.5 12.6736 12.9653 11.6042 11.8958C10.5347 10.8264 10 9.52778 10 8C10 6.47222 10.5347 5.17361 11.6042 4.10417C12.6736 3.03472 13.9722 2.5 15.5 2.5C17.0278 2.5 18.3264 3.03472 19.3958 4.10417C20.4653 5.17361 21 6.47222 21 8C21 9.52778 20.4653 10.8264 19.3958 11.8958C18.3264 12.9653 17.0278 13.5 15.5 13.5ZM13.6208 10.75H12.5208L14.9042 4.33333H16.05L18.4792 10.75H17.3792L16.7833 9.1H14.2167L13.6208 10.75ZM14.5375 8.18333L15.4542 5.525H15.5458L16.4625 8.18333H14.5375Z"
                    fill="#BFC6DC"/>
              <path
                  d="M15 15.4367C15.3243 15.4789 15.6576 15.5 16 15.5C16.1762 15.5 16.35 15.4944 16.5214 15.4832L16.55 19.5H15V21.5H13V19.5H11.5V13.8816C11.9685 14.28 12.4685 14.6048 13 14.8561V17.5H15V15.4367Z"
                  fill="#BFC6DC"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7 21.5V16.5H9V9.5H7V5.5H5V9.5H3V16.5H5V21.5H7ZM7 14.5H5V11.5H7V14.5Z" fill="#BFC6DC"/>
            </svg>
        </span>
    </button>
</template>

<style scoped>
.autotrader-button {
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Surface1);
    cursor: pointer;
    overflow: hidden;
}

.state-layer {
    display: inline-block;
    padding: 6px;
}

.autotrader-button:hover .state-layer {
    background: var(--Statelayers-secondary-opacity08);
}

.autotrader-button:focus-visible .state-layer {
    background: var(--Statelayers-secondary-opacity12);
}

.autotrader-button:active .state-layer {
    background: var(--Statelayers-secondary-opacity16);
}

.autotrader-button svg path {
    fill: var(--System-Secondary);
}
</style>
