<template>
     <div class="search-country-select-wrapper">
        <div v-if="title" class="title body__medium">{{ title }}</div>
        <div class="search-country-select" 
            @focusout="handleFocusOut" 
            @keydown.escape="removeFocus"
        >
            <span class="fs small-flag" :class="`${getCountryCode(searchedCountry) ? 'flag flag-'+getCountryCode(searchedCountry) : ''}`"></span>
            <input  v-model="searchedCountry"   
                    placeholder="Search country"    
                    class="search-country-input body__large"    
                    @focus="isFilterList = false" />
            <i class="material-symbols-outlined">arrow_drop_down</i>
            <BaseMenuList 
                class="search-country-list" 
                :style="{'max-height': `${(maxShowed * 36) + 8}px`,
                        'display': countries.length > 0 ? '' : 'none'}">
                    <template #content>
                        <BaseMenuListItem
                            v-for="(countryItem, index) in countries"
                            :key="index"
                            :class="{ active: countryItem === country }"
                            :tag="'li'"
                            @mousedown.native="chooseCountry(countryItem)"
                            @keydown.space.native="chooseCountry(countryItem)">
                                <template #content>
                                    <span class="flag fs small-flag" :class="'flag-'+getCountryCode(countryItem)"></span>
                                    {{ countryItem }}
                                    <input type="checkbox">
                                </template>
                        </BaseMenuListItem>
                    </template>
            </BaseMenuList>
        </div>
    </div>
</template>


<script>
import { countriesList } from '@/common/helpers'
import BaseMenuList from "@/new-design/shared/ui/panels/BaseMenuList.vue"
import BaseMenuListItem from "@/new-design/shared/ui/panels/BaseMenuListItem.vue"

export default {
    name: "SearchCountrySelect",
    components: {BaseMenuList, BaseMenuListItem},
    props: {
        maxShowed: {
            type: Number,
            default: 5
        },
        value: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            searchedCountry: this.vaildatePropsValue(this.value),
            isFilterList: false,
            countriesList: countriesList()
        }
    },
    computed: {
        countries() {
            return !this.isFilterList ? Object.values(this.countriesList) : Object.values(this.countriesList).filter(country => country.toLowerCase().indexOf(this.searchedCountry.toLowerCase()) > -1)
        },
        country() {
            return this.vaildatePropsValue(this.value)
        },
        countriesListEntries() {
            return Object.entries(countriesList())
        }
    },
    methods: {
        chooseCountry(country) {
            this.removeFocus()
            if(Object.values(this.countriesList).includes(country)){
                if(this.country === country) return;
                this.searchedCountry = country
                this.$emit('update', this.searchedCountry)
            }else{
                this.searchedCountry = this.country
            }
        },
        vaildatePropsValue(value) {
            return Object.values(this.countriesList || {}).includes(value) ?  value : ''
        },
        removeFocus() {
            document.activeElement.blur();
        },
        handleFocusOut(event) {
            if (!this.$el.contains(event.relatedTarget)) {
                this.chooseCountry(this.searchedCountry);
            }
        },
        getCountryCode(country) {
            let res = this.countriesListEntries.find(([, value]) => (value === country))
            return res ? res[0].toLowerCase() : ''
        }
    },
    watch: {
        value() {
            this.searchedCountry = this.vaildatePropsValue(this.value)
        },
        searchedCountry(){
            this.isFilterList = true
        }
    }
}
</script>

<style scoped>
.title {
    display: flex;
    padding-bottom: 4px;
}

.search-country-select {
    position: relative;
}

.search-country-select > i {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    transition: transform .3s ease;
}

.search-country-select > span {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 1;
}

.search-country-select:focus-within i {
    transform: rotate(180deg)
}

.search-country-input {
    padding: 8px 33px;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
}

.search-country-input::placeholder {
    color: var(--System-Outline);
}

.search-country-select:focus-within .search-country-input {
    border-color: var(--System-Primary);
}

.search-country-list {
    display: none;
    position: absolute;
    top: 44px;
    width: 100%;
    overflow-x: auto;
    color: var(--System-OnSurface);
    padding: 8px 0;
    border-radius: 4px;
    background: var(--System-Surface3);
    flex-direction: column;
    z-index: 20;
}

.search-country-select:focus-within .search-country-list {
    display: flex;
}

.search-country-list li:hover,
li:focus-within{
    background: var(--Statelayers-onsurface-opacity08); 
}

.search-country-list li {
    padding: 8px 10px;
    cursor: pointer
}

.search-country-list li.active{
    background: var(--Statelayers-primary-opacity16);
}

.search-country-list input[type="checkbox"] {
    opacity: 0;
}
</style>