<template>
    <div class="widget">
        <BaseWidgetTitle :title="tokens.widgetOrders.activeOrders" @close="hideModal('orders')"/>
        <div class="tabs">
            <div class="tab-item" :class="{active: tabActive === 'active'}" @click="tabActive = 'active'">
                <span class="label__large">{{ tokens.widgetOrders.active }}</span>
            </div>
            <div class="tab-item label__large" :class="{active: tabActive === 'pending'}" @click="tabActive = 'pending'">
                <span>{{ tokens.widgetOrders.pending }}</span>
            </div>
        </div>
        <PositionsSelect
            class="select"
            :options="{
                    '': tokens.widgetOrders.allPositions,
                    forex: tokens.markets.forex,
                    stocks: tokens.markets.stocks,
                    indices: tokens.markets.indices,
                    commodities: tokens.markets.commodities,
                    crypto: tokens.markets.crypto,
                    metals: tokens.markets.metals,
                    nft: tokens.markets.nft
            }"
            :active="marketFilter"
            @update="marketFilter = $event"
        />
        <div class="content scroll">
            <div class="no-orders" :class="computeNoOrdersVisibility">
                <i class="material-icons">import_export</i>
                <span>{{ tokens.widgetOrders.noPositionsLine1 }}<br/>{{ tokens.widgetOrders.noPositionsLine2 }}</span>
            </div>
            <div class="col" :class="{hide: tabActive !== 'active'}">
                <div class="order-item"
                     v-for="(order, i) in orders"
                     :key="order.id"
                     :class="{
                             active: detailsOrder === order.id,
                             hide: marketFilter && (!symbols[order.symbol] || symbols[order.symbol].market !== marketFilter)
                         }"
                >
                    <div class="row">
                        <div class="col">
                            <div class="order-info">
                                <div class="name">
                                    <div class="symbol-content row" @click="changeSymbol(order.symbol)">
                                        <!--                                    <img :src="staticBase+order.symbol.toUpperCase()+'.png'" />-->
                                        <div class="symbol"
                                             :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : '' "/>
                                        <div class="col">
                                            <span>
                                                {{
                                                    symbols[order.symbol] ? symbols[order.symbol].alias : order.symbol
                                                }}
                                            </span>
                                            <span class="group">
                                                {{ symbols[order.symbol] && symbols[order.symbol].group && symbols[order.symbol].group  }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="profit-info">
                                        <span class="profit" :class="order.profit < 0 ? 'red' : 'green'">
                                           {{
                                                (0 > order.profit ? '-' : (order.profit > 0 ? '+' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toFixed(activeAccount ? activeAccount.precision : 2)
                                            }}
                                        </span>
                                        <span class="pct" :class="order.profit < 0 ? 'red' : 'green'">
                                        {{
                                                (order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + (symbols[order.symbol] ? (Math.abs(symbols[order.symbol].quote[order.type % 2 ? 'ask' : 'bid'] - order.price_open) / order.price_open * 100) : 0).toFixed(2) + '%'
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div class="details" :class="{hide: detailsOrder !== order.id}">
                                    <BaseButtonSmall
                                        class="close-btn ElevationLight__1"
                                        :button-text="tokens.widgetOrders.closePosition"
                                        :button-style="'tonal-secondary'"
                                        @click.native="showClosePositionModal(order.id, i)"
                                    />
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.purchaseTime }}</span>
                                        <span>{{ new Date(order.time_open * 1000).toLocaleString() }}</span>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.priceOpen }}</span>
                                        <span>{{
                                                order.price_open.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5)
                                            }}</span>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.priceSl }}</span>
                                        <div class="row edit-row">
                                            <span>{{
                                                    order.sl ? order.sl.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'
                                                }}</span>
                                            <BaseIconButton>
                                                <template #icon>
                                                    <i class="material-icons edit"
                                                       @click="showAutoCloseModal($event, order.id, i)">edit</i>
                                                </template>
                                            </BaseIconButton>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.priceTp }}</span>
                                        <div class="row edit-row">
                                            <span>{{
                                                    order.tp ? order.tp.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'
                                                }}</span>
                                            <BaseIconButton>
                                                <template #icon>
                                                    <i class="material-icons edit"
                                                       @click="showAutoCloseModal($event, order.id, i)">edit</i>
                                                </template>
                                            </BaseIconButton>
                                        </div>
                                    </div>
                                    <div class="item type">
                                        <span>{{ tokens.widgetOrders.type }}</span>
                                        <BasePositionDirection :type="order.type"/>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.volume }}</span>
                                        <span>{{ order.volume }}</span>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.margin }}</span>
                                        <span>{{
                                                currencySign(activeAccount.currency) + order.easy_amount.toFixed(activeAccount.precision)
                                            }}</span>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.pnl }}</span>
                                        <span>{{
                                                (order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toFixed(activeAccount.precision)
                                            }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="smaller" :class="{open: detailsOrder === order.id}">
                                <div class="row pointer"
                                     v-on:click="detailsOrder = detailsOrder === order.id ? 0 : order.id">
                                    <span class="info-state" :class="{open: detailsOrder === order.id}">
                                        {{ tokens.widgetOrders.show }} {{
                                            detailsOrder !== order.id ? tokens.widgetOrders.more : tokens.widgetOrders.less
                                        }}
                                    </span>
                                    <i class="material-icons">{{
                                            detailsOrder !== order.id ? 'arrow_drop_down' : 'arrow_drop_up'
                                        }}</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" :class="{hide: tabActive !== 'pending'}">
                <div class="order-item"
                     v-for="(order, i) in pendingOrders"
                     :key="order.id"
                     :class="{
                             active: detailsOrder === order.id,
                             hide: marketFilter && symbols[order.symbol].market !== marketFilter
                         }"
                >
                    <div class="row">
                        <div class="col">
                            <div class="order-info">
                                <div class="name">
                                    <div class="symbol-content row" @click="changeSymbol(order.symbol)">
                                        <!--                                    <img :src="staticBase+order.symbol.toUpperCase()+'.png'" />-->
                                        <div class="symbol"
                                             :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : '' "/>
                                        <div class="col">
                                            <span>
                                                {{
                                                    symbols[order.symbol] ? symbols[order.symbol].alias : order.symbol
                                                }}
                                            </span>
                                            <span class="group">
                                                {{ symbols[order.symbol] ? symbols[order.symbol].group : '' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="profit-info">
                                        <span class="profit" :class="order.profit < 0 ? 'red' : 'green'">
                                           {{
                                                (0 > order.profit ? '-' : (order.profit > 0 ? '+' : '')) + currencySign(activeAccount.currency) + Math.abs(order.profit).toFixed(activeAccount ? activeAccount.precision : 2)
                                            }}
                                        </span>
                                        <span class="pct" :class="order.profit < 0 ? 'red' : 'green'">
                                        {{
                                                (order.profit > 0 ? '+' : (0 > order.profit ? '-' : '')) + (symbols[order.symbol] ? (Math.abs(symbols[order.symbol].quote[order.type % 2 ? 'ask' : 'bid'] - order.price_open) / order.price_open * 100) : 0).toFixed(2) + '%'
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div class="details" :class="{hide: detailsOrder !== order.id}">
                                    <BaseButtonSmall
                                        class="close-btn ElevationLight__1"
                                        :button-text="tokens.widgetOrders.cancelOrder"
                                        :button-style="'tonal-secondary'"
                                        @click.native="showCancelPositionModal(order.id, i)"
                                    />
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.priceOpen }}</span>
                                        <span>{{
                                                order.price_open.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5)
                                            }}</span>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.priceSl }}</span>
                                        <span>{{
                                                order.sl ? order.sl.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'
                                            }}</span>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.priceTp }}</span>
                                        <span>{{
                                                order.tp ? order.tp.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'
                                            }}</span>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.orderType }}</span>
                                        <BasePositionDirection :type="order.type"/>
                                    </div>
                                    <div class="item">
                                        <span>{{ tokens.widgetOrders.volume }}</span>
                                        <span>{{ order.volume }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="smaller" :class="{open: detailsOrder === order.id}">
                                <div class="row pointer"
                                     v-on:click="detailsOrder = detailsOrder === order.id ? 0 : order.id">
                                    <span class="info-state" :class="{open: detailsOrder === order.id}">
                                        {{ tokens.widgetOrders.show }} {{
                                            detailsOrder !== order.id ? tokens.widgetOrders.more : tokens.widgetOrders.less
                                        }}
                                    </span>
                                    <i class="material-icons">{{
                                            detailsOrder !== order.id ? 'arrow_drop_down' : 'arrow_drop_up'
                                        }}</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MODAL,
    MODAL_AUTOCLOSE,
    MODAL_CLOSE_POSITION,
    MODAL_MENU,
    NOTIFICATION_ERROR,
    SYMBOL_ACTIVE_UPDATE
} from '@/store/mutations.type'
import {mapGetters} from 'vuex'
import {currencySign, isMobile} from '@/common/helpers'
import BaseWidgetTitle from "@/new-design/shared/ui/titles/BaseWidgetTitle.vue"
import BasePositionDirection from "@/new-design/shared/ui/panels/BasePositionDirection.vue"
import PositionsSelect from "@/new-design/shared/ui/selects/PositionsSelect"
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"

export default {
    name: 'OpenOrders',
    components: {BaseButtonSmall, BaseIconButton, BasePositionDirection, BaseWidgetTitle, PositionsSelect},
    data() {
        return {
            tabActive: 'active',
            marketFilter: '',
            detailsOrder: 0
        }
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for (let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        showClosePositionModal(id, index) {
            if (!this.activeAccount.enable) {
                this.$store.commit(NOTIFICATION_ERROR, 'TRADING_DISABLED')
                return
            }
            this.$store.commit(MODAL_CLOSE_POSITION, {
                activeOrderId: id,
                index: index
            })
            this.$store.commit(MODAL, {
                closePosition: true
            })
        },
        showCancelPositionModal(id, index) {
            if (!this.activeAccount.enable) {
                this.$store.commit(NOTIFICATION_ERROR, 'TRADING_DISABLED')
                return
            }
            this.$store.commit(MODAL_CLOSE_POSITION, {
                activeOrderId: id,
                index: index
            })
            this.$store.commit(MODAL, {
                canselPosition: true
            })
        },
        showAutoCloseModal(e, id, item) {
            let rect = e.target.getBoundingClientRect()

            this.$store.commit(MODAL_AUTOCLOSE, {
                left: rect.left + 200,
                top: rect.top - 100,
                symbolActive: null,
                orderActive: id,
                tp: this.activeAccount.orders[item].tp || '',
                tpType: '=',
                sl: this.activeAccount.orders[item].sl || '',
                slType: '=',
                item: parseInt(item)
            })

            this.$store.commit(MODAL, {
                autoClose: true
            })
        },
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        currencySign
    },
    computed: {
        ...mapGetters(['modals', 'symbols', 'activeAccount', 'tokens', 'chartActive', 'modalMenu', 'staticBase']),
        orders() {
            let orders = []

            if (!this.activeAccount.orders) {
                return orders
            }

            for (let n in this.activeAccount.orders) {
                let order = this.activeAccount.orders[n]

                if (order.type > 1) {
                    continue
                }

                if (!this.symbols[order.symbol]) {
                    order.profit = 0
                } else {
                    order.profit = (order.point * (order.type && this.symbols[order.symbol] ? order.price_open - this.symbols[order.symbol].quote.ask : this.symbols[order.symbol].quote.bid - order.price_open) * Math.pow(10, this.symbols[order.symbol].precision) + order.swap + order.commission)
                }

                order.easy_amount = order.easy_amount || order.margin

                orders.push(order)
            }

            return orders
        },
        pendingOrders() {
            let orders = []

            if (!this.activeAccount.orders) {
                return orders
            }

            for (let n in this.activeAccount.orders) {
                let order = this.activeAccount.orders[n]

                if (order.type < 2) {
                    continue
                }

                order.profit = 0
                order.easy_amount = order.easy_amount || order.margin

                orders.push(order)
            }

            return orders
        },
        computeNoOrdersVisibility() {
            if (this.orders.length > 0 || this.pendingOrders.length > 0) {
                return 'hide'
            } else {
                return ''
            }
        }
    }
}
</script>

<style scoped>
.tabs {
    display: flex;
    align-items: flex-end;
    margin: 0 12px 12px;
    padding: 4px;
    border-radius: var(--Corner-Extra-small);
    border: 1px solid var(--System-OutlineVariant);
    gap: 5px;
}

.tabs .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    padding: 5px 0;
    color: var(--System-Outline);
    cursor: pointer;
    text-transform: capitalize;
}

.tabs .tab-item:active,
.tabs .tab-item:hover {
    background: var(--Statelayers-outline-opacity12);
}

.tabs .tab-item.active {
    color: var(--System-Primary);
    background: var(--System-Surface5);
    border-radius: var(--Corner-Extra-small);
}

.tabs span {
    width: 100%;
    text-align: center;
    padding: 0 5px;
}

.select {
    padding: 12px;
    border-top: 1px solid var(--System-OutlineVariant);
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.content {
    padding: 16px 12px;
}

.smaller:hover .info-state {
    color: var(--System-Secondary);
}

.smaller:hover,
.smaller.open {
    background: var(--Statelayers-surface5-opacity16);
    color: var(--System-Secondary);
}

.info-state {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    color: var(--System-Outline);
}

.info-state.open {
    color: var(--System-Secondary);
}

.order-info {
    display: flex;
    flex-direction: column;
}

.details {
    padding: 0 16px;
}

.no-orders {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15px
}

.no-orders i {
    font-size: 60px;
}

.no-orders span {
    font-size: 1.2em;
    text-align: center;
}

.order-item {
    display: flex;
    flex-direction: column;
    border-radius: var(--Corner-Extra-small);
    border: 1px solid var(--System-OutlineVariant);
    margin-bottom: 16px;
}

.order-item .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 400;
    padding: 16px;
    color: var(--System-OnSurface)
}

.profit-info {
    display: flex;
    flex-direction: column;
}

.order-item .name img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.order-item .symbol {
    zoom: 0.30;
    -moz-transform: scale(0.30);
    -moz-transform-origin: 50% 50%;
    margin-right: 20px;
}

@-moz-document url-prefix() {
    .order-item .symbol {
        -moz-transform: scale(0.30);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: 0;
    }

    .order-item .name .row:first-child span {
        margin-left: 22px;
    }
}

.order-item .col {
    flex: 1 1 auto;
}

.order-item .smaller {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: center;
    border-top: 1px solid var(--System-OutlineVariant);
    padding: 8px 0;
    cursor: pointer;
}

.order-item .smaller i {
    font-size: 18px;
    color: var(--System-Outline);
}

.order-item .smaller:hover i {
    color: var(--System-Secondary);
}

.order-item .edit {
    font-size: 1em;
    color: var(--System-Primary);
    cursor: pointer;
}

.details {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 16px;
}

.details .close-position {
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Surface3);
    color: var(--System-Primary);
    margin-bottom: 16px;
    padding: 6px 0;
    cursor: pointer;
}

.details .item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    background-color: inherit;
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 8px;
    overflow: initial;
}

.details .item span:last-child {
    display: flex;
    align-items: center;
    color: var(--System-OnSurface);
}

.details .item span:last-child i {
    font-size: 16px;
}

.symbol {
    cursor: pointer;
}

.pointer {
    gap: 8px;
    align-items: center;
}

.symbol-content {
    align-items: center;
}

.group {
    color: var(--System-Outline);
    font-size: 10px;
}

.edit-row {
    align-items: center;
    gap: 5px;
}

.close-btn {
    margin-bottom: 16px;
}
</style>
