<template>
    <div class="single-psp">
        <h5 class="title body__medium">Order details</h5>
        <div class="order-info">
            <div class="currencies" @click="toggler()">
                <BaseSelectBox
                    v-model="currency"
                    :placeholder="currency"
                    :state="state"
                    :hide-arrow="true"
                    :title="'Currency'"
                    :design="'deposit'"
                    :placement="'full'"
                    :options="currencies.map(item => ({
                            key: item,
                            title: item
                        }))"
                    @update="changeCurrency"
                >
                    <template #placeholder>
                        <div class="placeholder">
                            <div class="icon">
                                <img :src="staticBase+`currencies/${currency ? currency : 'USD'}.png`" alt="">
                                <p class="body__large">{{currency}}</p>
                            </div>
                            <span class="material-symbols-outlined">{{state ? 'arrow_drop_up' : 'arrow_drop_down'}}</span>
                        </div>
                    </template>
                    <template #option="{option}">
                        <img :src="staticBase+`currencies/${option.key ? option.key : 'USD'}.png`" alt="">
                        <div class="name">{{ option.title }}</div>
                    </template>
                </BaseSelectBox>
            </div>
            <BaseInput class="amount" v-model="amount" :type="'number'" :title="'Amount'" :placeholder="'0.00'">
                <template #icon>
                    <p class="body__large">{{ currencySign(currency) }}</p>
                </template>
            </BaseInput>
        </div>
        <BaseModalChipsList class="chips">
            <template #content>
                <BaseModalChipsListItem
                    v-for="deposit in depositButtonsList"
                    :key="deposit.amount"
                    @click.native="setCurrency(deposit.amount)"
                >
                    <template #content>
                        <p>{{deposit.amountStr}}</p>
                    </template>
                </BaseModalChipsListItem>
            </template>
        </BaseModalChipsList>
        <div class="action">
            <p class="info body__extrasmall">*The deposit via {{ method.name }} is processed within 5-10 minutes</p>
            <BaseButton
                :button-text="'Continue'"
                :button-style="'filled-primary'"
                :disabled="!amount"
                @click.native="makeDeposit"
            />
        </div>
    </div>
</template>

<script>
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue"
import {currencySign} from "@/common/helpers"
import BaseModalChipsListItem from "@/new-design/shared/ui/chips/BaseModalChipsListItem.vue"
import BaseModalChipsList from "@/new-design/shared/ui/chips/BaseModalChipsList.vue"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"
import BaseSelectBox from "@/new-design/shared/ui/Inputs/BaseSelectBox.vue"
import toggler from "@/new-design/shared/mixins/toggler"
import {DEPOSIT_BUTTONS, MAXIMUM_DEPOSIT, MINIMUM_DEPOSIT, STATIC_BASE} from "@/common/config"
import {mapGetters} from "vuex";

export default {
    name: "SinglePspMethod",
    components: {BaseSelectBox, BaseButton, BaseModalChipsList, BaseModalChipsListItem, BaseInput},
    mixins: [toggler],
    mounted() {
        this.init()
    },
    props: {
        currencies: {
            type: Array,
            default: () => []
        },
        method: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            amount: null,
            staticBase: STATIC_BASE,
            selectedCurrency: '',
        }
    },
    computed: {
        currency: {
            get() {
                return this.selectedCurrency || (this.currencies.length ? this.currencies[0] : '')
            },
            set(value) {
                this.selectedCurrency = value
            }
        },
        depositButtonsList() {
            let buttons = []

            if (!this.method || !this.pspList || !DEPOSIT_BUTTONS[this.currency]) {
                return buttons
            }

            const methodConfig = this.pspList.find(data => data.name === this.method.key) || {}

            let minimum = methodConfig.deposit_min || MINIMUM_DEPOSIT
            let maximum = methodConfig.deposit_max || MAXIMUM_DEPOSIT

            if (this.user && this.user.min_deposit !== 0) {
                minimum = this.user.min_deposit
            }

            if (this.method.key === 'cc-ext3' && methodConfig.onlyButtons) {
                methodConfig.onlyButtons.forEach((button) => {
                    buttons.push({
                        amount: button.value,
                        amountStr: currencySign(this.currency) + button.value.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }),
                    })
                })
                return buttons
            }

            Object.keys(DEPOSIT_BUTTONS).forEach(depositCurrency => {
                let depositButtons = DEPOSIT_BUTTONS[depositCurrency]

                if (depositCurrency === 'RUB' && this.conversions[depositCurrency]) {
                    const convertedMin = (minimum / this.conversions[depositCurrency]).toFixed(4)
                    if (!depositButtons.includes(+convertedMin)) {
                        depositButtons.push(+convertedMin)
                    }
                } else {
                    if (!depositButtons.includes(minimum)) {
                        depositButtons.push(minimum)
                    }
                }

                depositButtons.sort((a, b) => a - b)
            })

            for (const amount of DEPOSIT_BUTTONS[this.currency]) {
                const convertedAmount = amount * (this.conversions[this.currency] || 1)

                if (convertedAmount >= minimum && convertedAmount <= maximum) {
                    buttons.push({
                        amount,
                        amountStr: currencySign(this.currency) + amount.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                    })
                }
            }

            return buttons
        },
        ...mapGetters(['conversions', 'user', 'pspList'])
    },
    methods: {
        currencySign,
        init() {
            this.amount = null
        },
        changeCurrency(currency) {
            this.currency = currency
        },
        makeDeposit() {
            this.$emit('update', {
                info: {
                    amount: this.amount,
                    currency: this.currency
                }
            })
        },
        setCurrency(amount) {
            this.amount = amount
        }
    },
    watch: {
        'method': {
            handler() {
                this.init()
            }
        }
    }
}
</script>

<style scoped>
.title {
    color: var(--System-OnSurface);
    padding: 16px 0;
}

.chips {
    padding: 12px 0 16px;
}

.order-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.currencies {
    position: relative;
    cursor: pointer;
}

img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 8px 8px 8px;
    outline: none;
    height: 100%;
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
}

.icon {
    display: flex;
    align-items: center;
    gap: 4px;
}

.info {
    text-align: left;
}

@media (max-width: 468px) {
    .title {
        text-align: left;
    }

    .order-info {
        grid-template-columns: 1fr;
    }

    .action {
        gap: 24px;
    }
}
</style>
