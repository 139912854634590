<template>
    <div class="info-text">
        <span class="body__small">{{message}}</span>
    </div>
</template>

<script>
export default {
    name: "BaseTextInfo",
    props: {
        message: {
            type: String
        }
    }
}
</script>

<style scoped>
.info-text {
    border-radius: var(--Corner-small);
    background: var(--System-Surface5);
    padding: 8px;
}

.info-text span {
    color: var(--System-OnSurfaceVariant);
}
</style>
