<template>
    <div class="modal-wrapper" :class="{ hide: !modals.balance }" @click.stop="hideModal">
        <div class="modal" @click.stop>
            <div class="modal-title">
                <BalanceModalTitle :title="'Active account'" @close="hideModal">
                    <template #right>
                        <div class="account-link label__small">
                            <a @click="showCabinet('accounts')">
                                {{ activeAccount.demo ?
                                    tokens.balanceModal.practice :
                                    tokens.balanceModal.real }}
                                {{ tokens.balanceModal.account }} #{{ activeAccount.id }}
                                <i class="material-symbols-outlined">open_in_new</i>
                            </a>
                        </div>
                        <BaseButton
                            :button-style="'filled-primary'"
                            :button-text="'Save'"
                            class="title-button"
                            @click.native="hideModal" />
                    </template>
                </BalanceModalTitle>
            </div>
            <div class="modal-body">
                <AccountList />
            </div>
        </div>
    </div>
</template>

<script>
import {MODAL, MODAL_FULLSCREEN} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import {DEMO_ACCOUNT_RESET} from "@/store/actions.type";
import AccountList from "@/new-design/not-sorted/new/Modals/AccountList.vue";
import BalanceModalTitle from "@/new-design/shared/ui/titles/BalanceModalTitle.vue";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue";

export default {
    name: "BalanceModal",
    components: {
        AccountList,
        BalanceModalTitle,
        BaseButton
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                balance: false
            })
        },
        showCabinet(page) {
            this.$store.commit(MODAL, {
                balance: false,
                fullscreen: true,
            })
            this.$store.commit(MODAL_FULLSCREEN, {
                page
            })
        },
        resetDemo() {
            this.$store.dispatch(DEMO_ACCOUNT_RESET)
        },
    },
    computed: {
        ...mapGetters(['modals', 'activeAccount', 'tokens']),
    }
}
</script>

<style scoped>
    .modal-wrapper {
        background: transparent;
    }

    .new-design.banner-is-active .modal {
        top: calc(65px + 44px);
    }

    .modal {
        position: fixed;
        z-index: 2;
        right: 5px;
        top: 65px;
        width: 650px;
        display: grid;
        grid-template-columns: 1fr 250px;
        grid-template-rows: max-content 1fr;
        grid-gap: 15px 0;
        background: var(--System-Surface2);
        padding-bottom: 15px;
        border-radius: 8px;
        min-height: 340px;
    }

    .modal-title {
        grid-column: 1 / 3;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--System-OutlineVariant);
        padding: 0 6px;
    }

    .title {
        color: var(--System-OnSurface)
    }

    .account-link a {
        color: var(--System-OnSurfaceVariant);
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
    }

    .account-link i {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .modal-body {
        padding: 0 15px;
        max-height: 400px;
        overflow-y: auto;
    }

    .title-button {
        display: none;
    }

    @media (max-width: 1023px) {
        .modal {
            right: 10px;
        }
    }

    @media (max-width: 767px) {
        .modal {
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            grid-template-columns: 1fr;
            grid-template-rows: max-content max-content 1fr;
            background:var(--System-Surface);
        }

        .modal-title {
            grid-column: auto;
            padding: 35px 6px;
        }

        .modal-body {
            max-height: 100dvh;
            padding-bottom: 80px;
        }

        .title-button {
            display: block;
        }

        .account-link {
           display: none;
        }
    }
</style>
