<template>
    <div class="account">
        <BaseAccountSelect
            v-ripple="{duration: '.5s'}"
            class="account-select"
            :selectedAccount="selectedAccountComputed"
            @update="selectedAccount = $event" />
        <div class="account-id body__extrasmall">
            <span> {{ selectedAccountComputed.currency }}</span>
            <span>{{ String(selectedAccountComputed.product).toUpperCase() + ' #' + selectedAccountComputed.id }}</span>
        </div>
        <ul class="account-info">
            <li class="account-currency label__mediumprominent">
                <span>{{ 'Currency' }}</span>
                <span> {{ selectedAccountComputed.currency }}</span>
            </li>
            <li class="account-balance label__mediumprominent">
                <span>{{ tokens.fullScreenDashboard.balance }}</span>
                <span>
                    {{ currencySign(selectedAccountComputed.currency) + ' ' + (selectedAccountComputed.balance > 0 ?
                        selectedAccountComputed.balance :
                        0).toLocaleString(undefined, {
                            minimumFractionDigits: selectedAccountComputed.precision,
                            maximumFractionDigits: selectedAccountComputed.precision
                        })
                    }}
                </span>
            </li>
            <li v-show="parseInt(showLeverage) === 1"
                class="account-leverage label__mediumprominent" >
                    <span>{{ tokens.fullScreenDashboard.leverage }}</span>
                    <span class="value">
                        {{ '1:' + selectedAccountComputed.leverage }}
                    </span>
            </li>
            <li class="account-credit label__mediumprominent">
                <span>{{ tokens.fullScreenDashboard.credit }}</span>
                <span class="value" >
                    {{ currencySign(selectedAccountComputed.currency) + ' ' +
                        (selectedAccountComputed.credit).toLocaleString(undefined, {
                        minimumFractionDigits: selectedAccountComputed.precision,
                        maximumFractionDigits: selectedAccountComputed.precision
                        })
                    }}
                </span>
            </li>
        </ul>
        <BaseButton
            class="account-button"
            :buttonText="tokens.fullScreenDashboard.tradeNow"
            :buttonStyle="'outlined-primary'"
            @click.native="makeActive(selectedAccountComputed.id)" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseAccountSelect from "@/new-design/shared/ui/selects/BaseAccountSelect.vue";
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue";
import { SHOW_LEVERAGE } from "@/common/config"
import { ACCOUNT_ACTIVE, MODAL } from "@/store/mutations.type"
import { currencySign } from '@/common/helpers'


export default {
    name: "DashboardAccounts",
    components: { BaseAccountSelect, BaseButton },

    data() {
        return {
            selectedAccount: null,
            showLeverage: SHOW_LEVERAGE,
        }

    },
    computed: {
        ...mapGetters(['accounts', 'activeAccount', 'tokens', 'conversions']),
        selectedAccountComputed() {
            return this.selectedAccount || this.activeAccount
        }
    },
    methods: {
        makeActive(id) {
            this.$store.commit(MODAL, {
                fullscreen: false
            })
            localStorage.setItem('activeAccount', id)
            this.$store.commit(ACCOUNT_ACTIVE, id)
        },
        currencySign
    },
}
</script>

<style scoped>
.account {
    border-radius: 8px;
    background: linear-gradient(
        358deg,
        rgba(74, 126, 255, 0.20) 1.06%,
        rgba(97, 190, 218, 0.20) 103.26%);
    padding: 12px;
    width: 220px;
    display: flex;
    flex-direction: column;
}

.account-id {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--System-Outline);
    margin-top: 22px;
}

.account-id span:first-child {
    display: none;
}

.account-info {
    margin-top: 20px;
}

.account-info li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    gap: 6px;
}

.account-info li span:last-child {
    color: var(--System-OnSurface);
    font-weight: 500;
}

/* needs refactoring */
button {
    margin-top: auto;
    width: 100%;
    height: max-content !important;
}

@media (max-width: 1023px) {
    .account {
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-columns: minmax(185px, 200px) auto 105px;
        grid-gap: 0 15px;
        width: 100%;
    }

    .account-select {
        grid-column: 1/2;
        grid-row: 1/2
    }

    .account-button {
        grid-row: 1/2;
        grid-column: 3/4;
    }

    .account-id {
        grid-row: 2/auto;
        grid-column: 1/4;
        display: flex;
        justify-content: space-between;
        margin-top: 28px;
    }

    .account-id span:first-child {
        display: block;
    }

    .account-info {
        grid-row: 3/auto;
        grid-column: 1/4;
        display: flex;
        gap: 15px;
    }

    .account-info .account-currency {
        display: none;
    }
}

@media (max-width: 565px) {
    .account {
        grid-template-columns: 185px 105px;
        justify-content: space-between;
        grid-gap: 0 5px;
        width: 100%;
    }

    .account-select {
        grid-column: 1/2;
    }

    .account-button {
        grid-column: 2/3
    }

    .account-id,
    .account-info {
        grid-column: 1/3;
    }

    .account-info {
       overflow-x: auto
    }
    
    .account-info li{
        white-space: nowrap;
    }
}
</style>
