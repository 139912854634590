<template>
    <div class="small-chip">
        <div class="state-layer">
            <div v-if="$slots['icon']" class="icon">
                <slot name="icon"/>
            </div>
            <h6 class="title label__large">{{text}}</h6>
            <div
                v-if="showClose"
                class="close"
                @click="close"
            >
             <i class="material-symbols-outlined">close</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseSmallChip",
    props: {
        showClose: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.small-chip {
    background: var(--System-Surface1);
    cursor: pointer;
    border-radius: var(--Corner-Extra-small);
    width: fit-content;
}

.small-chip:hover {
    background: var(--Statelayers-onsurfacevariant-opacity08);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30);
}

.small-chip:active {
    background: var(--System-Surface1);
}

.title {
    font-weight: 500;
}

.state-layer {
    padding: 4px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.state-layer .icon {
    color: var(--System-OnSurfaceVariant);
}

.state-layer .icon path {
    fill: var(--System-OnSurfaceVariant);
}

.state-layer .close {
    color: var(--System-OnSurfaceVariant);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.state-layer:hover {
    background: var(--Statelayers-surfacevariant-opacity08);
}

.small-chip.active {
    background: var(--System-Surface5);
    color: var(--System-Primary);
}

.small-chip.active .icon {
    color: var(--System-Primary);
}

.small-chip.active .icon {
    fill: var(--System-Primary);
}

.small-chip.active .state-layer:hover {
    background: var(--Statelayers-primary-opacity08);
}
</style>
